import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { FiPlusCircle, FiMoreHorizontal } from 'react-icons/fi';
import { SimpleModal } from '../Modal';
import { ReactComponent as Stars } from './img/stars.svg';
import Dropdown from 'app/components/Dropdown';
import { useClient } from 'jsonapi-react';
import Form from 'app/components/CustomForm';
import { WarningModal } from '../Modal/WarningModal';
import * as yup from 'yup';

export default function QuestionModal(props) {
  const { show, onClose, text, form, matterIndex, setDeletedQuestions, taxonomies, showForm, setShowForm } = props;

  const client = useClient();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [showWarning, setShowWarning] = useState(false);

  const parentTaxonomy = taxonomies.find(item => item.id == form.values?.['taxonomy-id'])?.name;
  const childrenTaxonomy = form?.values?.matters?.[matterIndex]?.['taxonomy-name'];

  function handleCreateByAI() {
    const getQuestion = client.fetch(`/challenges/questions/generate?theme=${parentTaxonomy}&subtheme=${childrenTaxonomy}`);

    const loadingToastId = toast.loading('Nossa IA está elaborando a melhor pergunta');

    getQuestion
      .then(data => {
        const result = data.data.data;
        if (Object.keys(result).length > 0) {
          questionForm.setValues({
            comment: result?.comment,
            description: result?.description,
            'question-alternatives': result?.['challenge-alternatives-attributes']
          });
          toast.dismiss(loadingToastId);
          toast.success('Pergunta elaborada com sucesso!');
        } else {
          toast.dismiss(loadingToastId);
          toast.error('Erro ao elaborar pergunta');
          return Promise.reject('Erro ao elaborar pergunta');
        }
      })
      .catch(() => {
        toast.dismiss(loadingToastId);
        toast.error('Erro ao elaborar pergunta');
      });
  }

  const handleAddQuestion = () => {
    if (typeof currentQuestionIndex === 'number') {
      form.setFieldValue(`matters.[${matterIndex}].questions[${currentQuestionIndex}]`, questionForm.values);
    } else {
      form.setFieldValue(`matters.[${matterIndex}].questions`, [...form.values.matters[matterIndex]?.questions, questionForm.values]);
    }
    questionForm.resetForm();
    setCurrentQuestionIndex(null);
    setShowForm(false);
  };

  const validationSchema = yup.object().shape({
    comment: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    'question-alternatives': yup
      .array()
      .of(
        yup.object().shape({
          description: yup.string().required('Campo obrigatório')
        })
      )
      .length(4, 'Campo obrigatório')
  });

  const questionForm = useFormik({
    initialValues: {
      comment: '',
      description: '',
      'question-alternatives': [
        { description: '', 'is-correct': true },
        { description: '', 'is-correct': false },
        { description: '', 'is-correct': false },
        { description: '', 'is-correct': false }
      ]
    },
    onSubmit: handleAddQuestion,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  useEffect(() => {
    if (typeof currentQuestionIndex === 'number') {
      questionForm.setValues({
        comment: form.values.matters[matterIndex].questions?.[currentQuestionIndex]?.comment,
        description: form.values.matters[matterIndex].questions?.[currentQuestionIndex]?.description,
        'question-alternatives': form.values.matters[matterIndex].questions?.[currentQuestionIndex]?.['question-alternatives'],
        id: form.values.matters[matterIndex].questions?.[currentQuestionIndex]?.id
      });
    }
  }, [currentQuestionIndex]);

  const hasQuestions = form?.values?.['matters'][matterIndex]?.questions?.length > 0;
  const questions = form?.values?.['matters']?.[matterIndex]?.questions;

  const handleDeleteQuestion = () => {
    const filteredQuestions = questions.filter((item, index) => {
      if (index !== currentQuestionIndex) {
        return true;
      } else {
        setDeletedQuestions(prevState => [...prevState, { id: item.id, _destroy: true }]);
      }
    });

    form.setFieldValue(`matters.[${matterIndex}].questions`, filteredQuestions);
    setShowWarning(false);
  };

  const handleAlternativeChange = index => {
    questionForm.values['question-alternatives'].forEach((_, altIndex) => {
      questionForm.setFieldValue(`question-alternatives[${altIndex}].is-correct`, altIndex === index);
    });
  };

  return (
    <SimpleModal
      show={show}
      onClose={() => {
        onClose();
        setShowForm(false);
        setCurrentQuestionIndex(null);
        questionForm.resetForm();
      }}
      contentClassName="simple-modal__content--md simple-modal__content--br-md"
      hideCloseButton
    >
      <h3 className="simple-modal__title">{text}</h3>

      {!showForm && (
        <div className={hasQuestions ? 'question-btn-wrapper' : 'question-btn-wrapper question-btn-wrapper--empty'}>
          <button
            type="button"
            className="btn btn--primary"
            onClick={() => setShowForm(true)}
          >
            <FiPlusCircle className="btn__icon btn__icon--md" />
            Adicionar pergunta
          </button>

          {hasQuestions && (
            <div className="question-btn-wrapper__list">
              {questions.map((q, i) => (
                <div
                  className="question-btn"
                  onClick={() => setShowForm(true)}
                  key={i}
                >
                  <h3 className="question-btn__title">Pergunta {i + 1}</h3>
                  <p className="question-btn__text">{q.description}</p>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <span className="card__icon-wrapper card__icon-wrapper--sm">
                        <FiMoreHorizontal />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Content>
                      <Dropdown.Item
                        onClick={() => {
                          setShowForm(true);
                          setCurrentQuestionIndex(i);
                        }}
                      >
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentQuestionIndex(i);
                          setShowWarning(true);
                        }}
                      >
                        Excluir
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {showForm && (
        <>
          <form onSubmit={questionForm.handleSubmit}>
            <div className="question-btn__form">
              <div className="form__row">
                <div className="form__ai-banner">
                  <h3 className="form__ai-banner-title">Nossa IA te ajuda com o desafio</h3>
                  <button
                    className="btn btn--small btn--review"
                    type="button"
                    onClick={handleCreateByAI}
                  >
                    <Stars className="btn__icon challenge-success__stars challenge-success__stars--icon" />
                    Criar com ajuda da T.I.A
                  </button>
                </div>
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="enunciation"
                >
                  *Enunciado
                </label>

                <Form.TextArea
                  placeholder="Nomes criativos despertam a curiosidade dos estudantes"
                  id="enunciation"
                  name="enunciation"
                  value={questionForm?.values?.description}
                  onChange={e => questionForm.setFieldValue('description', e.target.value)}
                  counter={200}
                />
                {questionForm.errors.description && <span style={{ color: 'red' }}>{questionForm.errors.description}</span>}
              </div>

              <fieldset className="form__row">
                <legend className="form__label">*Alternativas</legend>
                <p className="form__description">
                  Escreva o texto das alternativas e <strong>marque a correta</strong>
                </p>

                <ol className="list-alternatives">
                  {questionForm?.values?.['question-alternatives']?.map((alternative, index) => {
                    const letter = {
                      0: 'A',
                      1: 'B',
                      2: 'C',
                      3: 'D'
                    };

                    return (
                      <li
                        className="list-alternatives__item"
                        key={index}
                      >
                        <div className="list-alternatives__container">
                          <input
                            className="form__check-input"
                            type="radio"
                            name={`alternatives-attributes.is-correct`}
                            onChange={() => handleAlternativeChange(index)}
                            value={index}
                            checked={alternative?.['is-correct'] || alternative?.is_correct || false}
                            id={index}
                          />

                          <Form.Control
                            placeholder={`Alternativa ${letter[index]}`}
                            id="name"
                            name="name"
                            type="text"
                            value={questionForm.values?.['question-alternatives']?.[index]?.description}
                            onChange={e => questionForm.setFieldValue(`question-alternatives[${index}].description`, e.target.value)}
                            counter={100}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ol>
                {questionForm?.errors?.['question-alternatives']?.length > 0 && <span style={{ color: 'red' }}>Todas alternativas devem ser preenchidas</span>}
              </fieldset>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="comment"
                >
                  *Comentário
                </label>
                <Form.TextArea
                  placeholder="O comentário aparecerá depois que a questão for respondida"
                  id="comment"
                  name="comment"
                  value={questionForm?.values?.comment}
                  onChange={e => questionForm.setFieldValue(`comment`, e.target.value)}
                  counter={150}
                />
                {questionForm.errors.comment && <span style={{ color: 'red' }}>{questionForm.errors.comment}</span>}
              </div>
            </div>

            <div className="form__row">
              <button
                type="submit"
                className="btn btn--wide btn--primary"
              >
                Salvar
              </button>
            </div>
          </form>
        </>
      )}

      <WarningModal
        show={showWarning}
        titleFirstButton="Sim"
        titleSecondButton="Não"
        onClose={() => setShowWarning(false)}
        title={'Você tem certeza que deseja excluir essa pergunta?'}
        description={'Essa ação não poderá ser desfeita'}
        onSubmitFirstButton={() => handleDeleteQuestion()}
        onSubmitSecondButton={() => setShowWarning(false)}
      />
    </SimpleModal>
  );
}
