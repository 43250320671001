import React, { useState } from 'react';
import { FiChevronDown, FiStar, FiFileText } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import moment from 'moment';
import 'moment/locale/pt';
import Swal from 'sweetalert2';
import { Link } from '@reach/router';
import { useSurvey } from 'app/hooks/useSurvey';
import getPermission from 'app/utils/getPermission';
import { SurveyForm } from './SurveyForm';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';

export default function QuizCard(props) {
  const { t } = useTranslation();
  const { survey, onDelete, relationshipsLoading, relationships } = props;
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { setCurrentSurvey } = useSurvey();

  const quizStatusClass = status => {
    switch (status) {
      case 'Inativo':
        return 'badge--danger';
      case 'Ativo':
        return 'badge--success';
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteQuest'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        onDelete();
      }
    });
  };

  return (
    <div className="card">
      <div className="card__container">
        <span className="card__icon-wrapper card__icon-wrapper--sm">
          <FiStar className="card__icon card__icon--md" />
        </span>

        <div className="card__content">
          <header className="card__header">
            <div className="card__inner-header">
              <h3
                className="card__title"
                title={survey.title}
              >
                {survey.title}
              </h3>
            </div>

            <span className={`badge badge--success badge--tiny ${quizStatusClass(survey.about.status)}`}>{survey.about.status}</span>
          </header>

          <div className="card__body">
            <div className="card__columns">
              <p
                className="card__count"
                title={`${survey.about['questions-count']} ${t('nps.questions')}`}
              >
                {survey.representation === 'stars' ? <FiStar className="card__icon" /> : <FiFileText className="card__icon" />} {survey.about['questions-count']}
              </p>
              <p className="card__info">
                <span>{t('textsCommon.availableUntil')}: </span>
                {moment(survey['finished-at']).format(t('datesForm.formatDate'))}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {survey.representation === 'stars' && (
            <Link
              to={`/questionario/criar/estrela/${survey.id}`}
              type="button"
              className="btn btn--outline btn--small"
              onClick={() => {
                setCurrentSurvey(survey);
              }}
            >
              {t('nps.accessQuest')}
            </Link>
          )}
          {survey.representation === 'text' && (
            <Link
              to={`/questionario/criar/aberto/${survey.id}`}
              type="button"
              className="btn btn--outline btn--small"
              onClick={() => {
                setCurrentSurvey(survey);
              }}
            >
              {t('nps.accessQuest')}
            </Link>
          )}

          {getPermission('Excluir questionários', 'Questionário de Percepção') || getPermission('Editar questionários', 'Questionário de Percepção') ? (
            <Dropdown className="btn--small btn btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Excluir questionários', 'Questionário de Percepção') && (
                  <Dropdown.Item
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    {t('button.delete')}
                  </Dropdown.Item>
                )}
                {getPermission('Editar questionários', 'Questionário de Percepção') && (
                  <Dropdown.Item
                    onClick={() => {
                      setShowModal(true);
                      setActiveSurvey(survey);
                    }}
                  >
                    {t('button.edit')}
                  </Dropdown.Item>
                )}

                {survey.representation === 'stars' && <Dropdown.Item to={`/questionario/estrela/${survey.id}`}>{t('button.viewData')}</Dropdown.Item>}
                {survey.representation === 'text' && <Dropdown.Item to={`/questionario/aberto/${survey.id}`}>Ver respostas</Dropdown.Item>}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setActiveSurvey(null);
        }}
      >
        <SurveyForm
          relationshipsLoading={relationshipsLoading}
          relationships={relationships}
          activeSurvey={activeSurvey}
          onClose={() => {
            setShowModal(false);
            setActiveSurvey(null);
          }}
        />
      </Modal>
    </div>
  );
}
