import React from 'react';
import moment from 'moment';
import { FiCheckCircle, FiMoreHorizontal } from 'react-icons/fi';
import { Link } from '@reach/router';
import { useQuestionBook } from '../../hooks/useQuestionBook';
import Dropdown from '../Dropdown';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useSession } from 'app/hooks/useSession';
import IconExercises from '../Icons/IconExercises';

export function CardArchive(props) {
  const { t } = useTranslation();
  const { modifier, book, selectedTab } = props;
  const hightlight = modifier?.includes('highlight');
  const { session } = useSession();

  const isPrivate = book?.['user-id'] === session?.user?.id;

  let className = 'card';
  if (!book) {
    return null;
  }
  if (hightlight) {
    className += ' card--highlight';
  }
  if (book?.['learning-system-question-books']?.[0]?.is_required) {
    className += ' card--danger';
  }

  const { deleteQuestionBook } = useQuestionBook();

  const confirmDestroy = book => {
    Swal.fire({
      title: t('projects.warningDeleteQuestionBook'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteQuestionBook(book.id);
      }
    });
  };

  function difficulty(param) {
    switch (param) {
      case t('questionBooks.hard'):
        return 'badge--danger';
      case t('questionBooks.medium'):
        return 'badge--warning';
      default:
        return 'badge--success';
    }
  }

  const questionCount = book && book['question-count'] > 1 ? `${book['question-count']} ${t('exams.questions')}` : '1 questão';

  return (
    <div className={className}>
      <div className="card__container">
        <span className="card__icon-wrapper card__icon-wrapper--sm">
          <IconExercises className="card__icon card__icon--md" />
        </span>

        <div className="card__content">
          <header className="card__header">
            <div className="card__inner-header">
              <h2 className="card__title">{!hightlight ? book.title : 'Questões avulsas'}</h2>
              {/*<span className="archive-card__status">Nunca respondido</span>*/}

              {!modifier?.includes('highlight') && isPrivate && (
                <Dropdown>
                  <Dropdown.Toggle>
                    <FiMoreHorizontal />
                  </Dropdown.Toggle>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => confirmDestroy(book)}>{t('textsCommon.delete')}</Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown>
              )}
            </div>

            {/* baDGE OBrigatorio */}
            <div className="card__badges">
              <span className={book?.['learning-system-question-books']?.[0]?.is_required === true ? 'badge badge--danger badge--tiny' : ''}>{book?.['learning-system-question-books']?.[0]?.is_required === true ? 'Obrigatório' : ''}</span>
              <span className={`badge badge--tiny badge--success ${difficulty(book['t-difficulty'])}`}>{book['t-difficulty']}</span>
            </div>
          </header>

          <div className="card__body">
            {book.taxonomies.length > 0 && (
              <div className="card__badges">
                {book.taxonomies.length <= 2 &&
                  book.taxonomies.map(t => (
                    <span
                      className="badge badge--primary"
                      key={t.id}
                      title={t.name}
                    >
                      {t.name}
                    </span>
                  ))}

                {book.taxonomies.length > 2 && (
                  <>
                    {book.taxonomies.slice(0, 2).map(t => (
                      <span
                        className="badge badge--primary"
                        key={t.id}
                        title={t.name}
                      >
                        {t.name}
                      </span>
                    ))}
                    <span
                      className="badge badge--more"
                      title={book.taxonomies.slice(2).map(t => ` ${t.name}`)}
                    >
                      +{book.taxonomies.length - 2}
                    </span>
                  </>
                )}
              </div>
            )}

            <div className="card__columns">
              <p
                className="card__count"
                title={questionCount}
                aria-label={questionCount}
              >
                <FiCheckCircle className="card__icon" /> {book['question-count']}
              </p>
              <p className="card__info">
                <span>{t('questionBooks.publishIn')}</span>
                {moment(book['published-at']).format(t('datesForm.formatDate'))}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card__footer">
        {/* {!modifier?.includes('highlight') && (
					<div className="archive-card__time">
						{book['resolution-time'] === '01:00' && <FiClock />}
						{book['resolution-time'] === '02:00' && (
							<>
								<FiClock />
								<FiClock />
							</>
						)}
						{book['resolution-time'] === '03:00' && (
							<>
								<FiClock />
								<FiClock />
								<FiClock />
							</>
						)}
					</div>
				)} */}

        <Link
          to={`/questions/apostilas/${book.id}`}
          className="btn btn--outline btn--small btn--full"
        >
          {t('questionBooks.buttonAccessQuestionBook')}
        </Link>
      </div>
    </div>
  );
}
