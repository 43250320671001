import React, { useEffect, useMemo } from 'react';
import { Link } from '@reach/router';
import { useFormik } from 'formik';
import { FiCheckCircle, FiChevronDown, FiPrinter } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import moment from 'moment';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import IconVerified from 'app/components/Icons/IconVerified';
import { useSession } from 'app/hooks/useSession';
import IconExams from 'app/components/Icons/IconExams';

export default function ExamCard(props) {
  const { t } = useTranslation();
  const { id, exam, handleDeleteExam, activeTabs, filteredExams } = props;
  const client = useClient();
  const currentUser = useSession().session.user;

  const examForm = useFormik({
    initialValues: {
      id: exam?.id,
      published: exam?.published
    }
  });

  useEffect(() => {
    examForm.setValues({
      id: exam?.id,
      published: exam?.published
    });
  }, [filteredExams]);

  const deleteExam = async () => {
    try {
      await client.delete(['question_books', exam.id]);
      handleDeleteExam(exam.id);
      toast.success(t('toast.successDeleteExam'));
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorDeleteExam'));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteExam'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteExam();
      }
    });
  };

  const updateExamPublicity = async () => {
    try {
      await client.mutate(['question_books', exam.id], {
        published: !examForm.values.published
      });
      if (!examForm.values.published) {
        toast.success(t('toast.successPublishExam'));
      } else {
        toast.success(t('toast.successUnpublishExam'));
      }
    } catch {
      toast.error(t('toast.errorPublishExam'));
    }
  };

  const shouldRenderOptions = useMemo(() => {
    if (exam?.['authored-by-user'] === true) {
      if (exam?.creator?.id === currentUser.id || currentUser?.profileNames?.includes('Administrador')) {
        return true;
      } else return false;
    }

    if (exam?.['authored-by-user'] === false) {
      if (getPermission('Editar provas', 'Provas e simulados') || getPermission('Excluir provas', 'Provas e simulados')) {
        return true;
      } else return false;
    }
  }, [exam]);

  const hasPermissionToPublish = getPermission('Gerenciar provas', 'Provas e simulados');
  const isCreatorOrAdmin = !exam?.['authored-by-user'] || (exam?.['authored-by-user'] && currentUser.id === exam?.creator.id) || currentUser?.profileNames.includes('Administrador');

  return (
    <div className="card">
      <div
        className="card__inner-header"
        style={{ minHeight: 32 }}
      >
        {hasPermissionToPublish && isCreatorOrAdmin && (
          <div
            className="form__check form__switch u-mb-0"
            style={{ flexGrow: '1' }}
          >
            <input
              className="form__check-input"
              id={`published-${id}`}
              name={`published-${id}`}
              type="checkbox"
              defaultChecked={examForm.values.published}
              onClick={() => {
                if (exam?.['question-count'] > 0) {
                  examForm.setFieldValue('published', !examForm.values.published);
                  updateExamPublicity();
                } else {
                  toast.error('Adicione questões para publicar esta prova');
                }
              }}
            />
            <label htmlFor={`publish-${id}`}>{t('exams.publishExam')}</label>
          </div>
        )}

        {exam?.['question-count'] > 0 && exam?.['question-count'] <= 100 && (
          <button
            className="card__print-btn"
            title={t('button.printExam')}
            onClick={() => navigate(`/avaliacoes/provas/impressao/${exam?.id}`)}
          >
            <FiPrinter />
          </button>
        )}
      </div>

      <div className="card__container">
        <span className="card__icon-wrapper card__icon-wrapper--sm">
          {exam?.['official-content'] && <IconVerified />}
          <IconExams className="card__icon card__icon--md" />
        </span>

        <div className="card__content">
          <div className="card__header">
            <div className="card__inner-header">
              <h2
                className="card__title"
                title="Nome da prova"
              >
                {exam?.title}
              </h2>
            </div>

            {exam.status == 'Não realizada' && <span className="badge badge--tiny badge--warning">{t('exams.statusUnrealized')}</span>}
            {exam.status == 'Realizada' && <span className="badge badge--tiny badge--success">{t('exams.statusDone')}</span>}
            {exam.status == 'Em andamento' && <span className="badge badge--tiny badge--warning">{t('exams.inProgress')}</span>}
          </div>

          <div className="card__body">
            <div className="card__badges card__badges--one-line">
              {exam?.taxonomies.length > 0 && (
                <div className="card__badges card__badges--one-line u-mb-0">
                  {exam?.taxonomies.length <= 2 &&
                    exam?.taxonomies.map(t => (
                      <span
                        className="badge badge--primary"
                        key={t.id}
                        title={t.name}
                      >
                        {t.name}
                      </span>
                    ))}

                  {exam?.taxonomies.length > 2 && (
                    <>
                      {exam?.taxonomies.slice(0, 2).map(t => (
                        <span
                          className="badge badge--primary"
                          key={t.id}
                          title={t.name}
                        >
                          {t.name}
                        </span>
                      ))}
                      <span
                        className="badge badge--more"
                        title={exam?.taxonomies.slice(2).map(t => ` ${t.name}`)}
                      >
                        +{exam?.taxonomies.length - 2}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="card__columns">
              <p className="card__count">
                <span className="card__count-label">{t('textsCommon.questions')}</span>
                <FiCheckCircle className="card__icon" /> {exam?.['question-count']}
              </p>

              {exam?.['published-at'] && (
                <p className="card__count">
                  <span>{t('exams.labelPublishedAt')}</span>
                  {moment(exam?.['published-at']).format(t('datesForm.formatDate'))}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card__footer">
        {exam.status === 'Realizada' && getPermission('Visualizar resultados', 'Provas e simulados') ? (
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            {getPermission('Gerenciar provas', 'Provas e simulados') && (
              <Link
                state={{ activeTabs: activeTabs }}
                to={`/provas/gerenciar/${exam.id}`}
                className="btn btn--outline btn--small"
              >
                {t('button.manage')}
              </Link>
            )}
          </div>
        ) : null}
        {exam.status === 'Em andamento' && getPermission('Visualizar resultados', 'Provas e simulados') ? (
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            {getPermission('Gerenciar provas', 'Provas e simulados') && (
              <Link
                to={`/provas/gerenciar/${exam.id}`}
                className="btn btn--outline btn--small"
              >
                {t('button.manage')}
              </Link>
            )}

            {shouldRenderOptions ? (
              <Dropdown className="btn--small btn btn--outline">
                <Dropdown.Toggle>
                  <FiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Content>
                  {exam?.['question-count'] > 0 && exam?.['question-count'] <= 100 && getPermission('Editar provas', 'Provas e simulados') && <Dropdown.Item to={`/avaliacoes/provas/offline/${exam.id}`}>{t('button.offlineExam')}</Dropdown.Item>}
                  {getPermission('Editar provas', 'Provas e simulados') && <Dropdown.Item to={`/provas/criar/curso/${exam.id}`}>{t('button.edit')}</Dropdown.Item>}
                  {getPermission('Excluir provas', 'Provas e simulados') && <Dropdown.Item onClick={handleDelete}>{t('button.delete')}</Dropdown.Item>}
                </Dropdown.Content>
              </Dropdown>
            ) : null}
          </div>
        ) : null}
        {exam.status === 'Não realizada' && getPermission('Criar provas e simulados', 'Provas e simulados') ? (
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            {getPermission('Gerenciar provas', 'Provas e simulados') && (
              <Link
                to={`/provas/gerenciar/${exam.id}`}
                className="btn btn--outline btn--small"
              >
                {t('button.manage')}
              </Link>
            )}

            {getPermission('Editar provas', 'Provas e simulados') || getPermission('Excluir provas', 'Provas e simulados') ? (
              <Dropdown className="btn--small btn btn--outline">
                <Dropdown.Toggle>
                  <FiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Content>
                  {exam?.['question-count'] > 0 && exam?.['question-count'] <= 100 && getPermission('Editar provas', 'Provas e simulados') && <Dropdown.Item to={`/avaliacoes/provas/offline/${exam.id}`}>{t('button.offlineExam')}</Dropdown.Item>}
                  {getPermission('Editar provas', 'Provas e simulados') && <Dropdown.Item to={`/provas/criar/curso/${exam.id}`}>{t('button.edit')}</Dropdown.Item>}
                  {getPermission('Excluir provas', 'Provas e simulados') && <Dropdown.Item onClick={handleDelete}>{t('button.delete')}</Dropdown.Item>}
                </Dropdown.Content>
              </Dropdown>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
