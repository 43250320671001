import { navigate } from '@reach/router';
import SimulationIcon from 'app/images/icons/simulation-icon';
import { FiClipboard, FiInfo } from 'react-icons/fi';
import { FiStar } from 'react-icons/fi';
import { BiJoystickAlt } from 'react-icons/bi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper/modules';

export default function Disclaimer({ alerts }) {
  const icon = type => {
    switch (type) {
      case 'challenge':
        return <BiJoystickAlt />;
      case 'project':
        return <FiClipboard />;
      case 'survey':
        return <FiStar />;
      case 'exam':
        return <SimulationIcon />;
      case 'info':
        return <FiInfo />;
    }
  };

  if (alerts?.length === 0 || alerts?.every(alert => alert?.['access_url'] === null)) return null;

  if (alerts?.length === 1) {
    const item = alerts[0];

    return (
      <div
        className="disclaimer"
        onClick={() => navigate(item.type === 'challenge' ? '/desafio-da-semana/desafios' : item?.['access_url'] ? item?.['access_url'] : '/')}
        style={{ marginBottom: 40 }}
      >
        <span className="disclaimer__icon">{icon(item.type)}</span>

        <div className="disclaimer__content">
          <h3
            className="disclaimer__title"
            title={item?.name}
          >
            {item?.name}
          </h3>
          <p
            className="disclaimer__text"
            title={item?.description}
          >
            {item?.description}
          </p>
        </div>
      </div>
    );
  }

  return (
    <Swiper
      className="disclaimer-wrapper"
      pagination={{ clickable: true }}
      modules={[Pagination, A11y]}
      spaceBetween={10}
      slidesPerView={1.5}
      style={{ paddingBottom: alerts?.length > 1 ? 22 : 0 }}
    >
      {alerts?.map((item, i) => {
        if (item?.['access_url'])
          return (
            <SwiperSlide key={i}>
              <div
                className="disclaimer"
                onClick={() => navigate(item.type === 'challenge' ? '/desafio-da-semana/desafios' : item?.['access_url'] ? item?.['access_url'] : '/')}
              >
                <span className="disclaimer__icon">{icon(item.type)}</span>

                <div className="disclaimer__content">
                  <h3
                    className="disclaimer__title"
                    title={item?.name}
                  >
                    {item?.name}
                  </h3>
                  <p
                    className="disclaimer__text"
                    title={item?.description}
                  >
                    {item?.description}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          );
      })}
    </Swiper>
  );
}
