import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { i18n } from 'app/components/i18n/i18n';

export default function DetailsStep(props) {
  const { t } = useTranslation();
  const { createMockQuestionBookForm } = props;
  moment.locale(t('language.language'));

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${String(i).padStart(2, '0')}`);
  }

  let minutes = [];
  for (let i = 0; i < 60; i++) {
    minutes.push(`${String(i).padStart(2, '0')}`);
  }

  const limitTime = [
    { id: 1, title: `1 ${t('tasks.hour')}`, value: '01:00' },
    { id: 2, title: `2 ${t('tasks.hours')}`, value: '02:00' },
    { id: 3, title: `3 ${t('tasks.hours')}`, value: '03:00' },
    { id: 4, title: `4 ${t('tasks.hours')}`, value: '04:00' },
    { id: 5, title: `5 ${t('tasks.hours')}`, value: '05:00' },
    { id: 6, title: `6 ${t('tasks.hours')}`, value: '06:00' },
    { id: 7, title: `7 ${t('tasks.hours')}`, value: '07:00' },
    { id: 8, title: `8 ${t('tasks.hours')}`, value: '08:00' }
  ];

  const correctionFactor = [
    { id: 1, title: t('exams.textAsideNoCorrectFactor'), value: 'without_factor' },
    { id: 2, title: t('exams.textAsideWrongCancelRight'), value: 'one_wrong_cancels_out_one_right' },
    { id: 3, title: 'Teoria de resposta ao item (TRI)', value: 'tri' }
  ];

  return (
    <div className="new-questions__body">
      <p className="new-questions__subtitle">{t('exams.detailsDescription')}</p>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="resolutionTime"
        >
          *{t('exams.limitTimeTitle')}
        </label>

        <select
          className="form__select"
          name="resolutionTime"
          id="resolutionTime"
          onChange={e => createMockQuestionBookForm.setFieldValue('resolutionTime', e.target.value)}
          value={createMockQuestionBookForm?.values?.resolutionTime}
        >
          <option value="">{t('filter.labelSelect')}</option>
          {limitTime?.map(t => (
            <option
              key={t.id}
              value={t.value}
            >
              {t.title}
            </option>
          ))}
        </select>

        {createMockQuestionBookForm?.touched.resolutionTime && createMockQuestionBookForm?.errors.resolutionTime && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors.resolutionTime}</span>}
      </div>

      <>
        <div className="form__row form__row--columns">
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="startDate"
            >
              *{t('exams.startDateTitle')}
            </label>
            <input
              className="form__control"
              id="evaluationStart"
              name="evaluationStart"
              type="date"
              onChange={e =>
                createMockQuestionBookForm.setFieldValue('evaluationStart', {
                  ...createMockQuestionBookForm.values.evaluationStart,
                  date: e.target.value
                })
              }
              value={moment(createMockQuestionBookForm?.values?.evaluationStart?.date).format('YYYY-MM-DD')}
            />
            {createMockQuestionBookForm?.touched?.evaluationStart?.date && createMockQuestionBookForm?.errors?.evaluationStart?.date && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.evaluationStart?.date}</span>}
          </div>

          <fieldset className="form__col">
            <legend className="form__label">*{t('exams.startHourTitle')}</legend>
            <div className="form__columns">
              <div className="form__col">
                <select
                  className="form__select"
                  name="startHour"
                  id="startHour"
                  aria-label="Hora"
                  onChange={e =>
                    createMockQuestionBookForm.setFieldValue('evaluationStart', {
                      ...createMockQuestionBookForm.values.evaluationStart,
                      hour: e.target.value
                    })
                  }
                  value={createMockQuestionBookForm?.values?.evaluationStart?.hour}
                >
                  {hours.map(i => (
                    <option
                      key={i}
                      value={parseInt(i)}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {createMockQuestionBookForm?.touched?.evaluationStart?.hour && createMockQuestionBookForm?.errors?.evaluationStart?.hour && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.evaluationStart?.hour}</span>}
              </div>

              <div className="form__col">
                <select
                  className="form__select"
                  name="startMinute"
                  id="startMinute"
                  aria-label="Minuto"
                  onChange={e =>
                    createMockQuestionBookForm.setFieldValue('evaluationStart', {
                      ...createMockQuestionBookForm.values.evaluationStart,
                      minute: e.target.value
                    })
                  }
                  value={createMockQuestionBookForm?.values?.evaluationStart?.minute}
                >
                  {minutes.map(i => (
                    <option
                      key={i}
                      value={parseInt(i)}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {createMockQuestionBookForm?.touched?.evaluationStart?.minute && createMockQuestionBookForm?.errors?.evaluationStart?.minute && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.evaluationStart?.minute}</span>}
              </div>
            </div>
          </fieldset>
        </div>

        <div className="form__row form__row--columns">
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="endDate"
            >
              *{t('exams.endDateTitle')}
            </label>
            <input
              className="form__control"
              id="endDate"
              name="endDate"
              type="date"
              onChange={e =>
                createMockQuestionBookForm.setFieldValue('evaluationEnd', {
                  ...createMockQuestionBookForm.values.evaluationEnd,
                  date: e.target.value
                })
              }
              value={moment(createMockQuestionBookForm?.values?.evaluationEnd?.date).format('YYYY-MM-DD')}
            />
            {createMockQuestionBookForm?.touched?.evaluationEnd?.date && createMockQuestionBookForm?.errors?.evaluationEnd?.date && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.evaluationEnd?.date}</span>}
          </div>

          <fieldset className="form__col">
            <legend className="form__label">*{t('exams.endHourTitle')}</legend>

            <div className="form__columns">
              <div className="form__col">
                <select
                  className="form__select"
                  name="startHour"
                  id="startHour"
                  aria-label="Hora"
                  onChange={e =>
                    createMockQuestionBookForm.setFieldValue('evaluationEnd', {
                      ...createMockQuestionBookForm.values.evaluationEnd,
                      hour: e.target.value
                    })
                  }
                  value={createMockQuestionBookForm?.values?.evaluationEnd?.hour}
                >
                  {hours.map(i => (
                    <option
                      key={i}
                      value={parseInt(i)}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {createMockQuestionBookForm?.touched?.evaluationEnd?.hour && createMockQuestionBookForm?.errors?.evaluationEnd?.hour && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.evaluationEnd?.hour}</span>}
              </div>

              <div className="form__col">
                <select
                  className="form__select"
                  name="startMinute"
                  id="startMinute"
                  aria-label="Minuto"
                  onChange={e =>
                    createMockQuestionBookForm.setFieldValue('evaluationEnd', {
                      ...createMockQuestionBookForm.values.evaluationEnd,
                      minute: e.target.value
                    })
                  }
                  value={createMockQuestionBookForm?.values?.evaluationEnd?.minute}
                >
                  {minutes.map(i => (
                    <option
                      key={i}
                      value={parseInt(i)}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {createMockQuestionBookForm?.touched?.evaluationEnd?.minute && createMockQuestionBookForm?.errors?.evaluationEnd?.minute && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.evaluationEnd?.minute}</span>}
              </div>
            </div>
          </fieldset>
        </div>
      </>

      <div className="form__row form__row--columns">
        <div className="form__col">
          <label
            className="form__label"
            htmlFor="limitTime"
          >
            *{t('exams.correctionFactorTitle')}
          </label>

          <select
            className="form__select"
            name="correctionFactor"
            id="correctionFactor"
            onChange={e => createMockQuestionBookForm.setFieldValue('correctionFactor', e.target.value)}
            value={createMockQuestionBookForm?.values?.correctionFactor}
          >
            <option value="">{t('filter.labelSelect')}</option>
            {correctionFactor?.map(o => (
              <option
                key={o.id}
                value={o.value}
              >
                {o.title}
              </option>
            ))}
          </select>
          {createMockQuestionBookForm?.touched?.correctionFactor && createMockQuestionBookForm?.errors?.correctionFactor && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors?.correctionFactor}</span>}
        </div>
      </div>

      <div className="form__row form__row--columns">
        <div className="form__col">
          <label
            className="form__label"
            htmlFor="limitTime"
          >
            Gabaritos
          </label>
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id="officialContent"
              name="officialContent"
              type="checkbox"
              checked={createMockQuestionBookForm?.values?.correct_after_user_finish}
              onClick={() => {
                createMockQuestionBookForm?.setFieldValue('correct_after_user_finish', !createMockQuestionBookForm?.values?.correct_after_user_finish);
              }}
            />
            <label htmlFor="officialContent">Marque para disponibilizar os gabaritos logo após as avaliações; senão, estarão disponíveis na data de "Fim de avaliação".</label>
          </div>
        </div>
      </div>
    </div>
  );
}
