import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useClient, useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import KpiWidget from './KpiWidget';
import { useSession } from 'app/hooks/useSession';
import FrequentShortcutWidget from './FrequentShortcutWidget';
import BreadCrumbs from 'app/components/BreadCrumbs';
import MetricsShortcuts from './MetricsShortcut';

function DashboardMetrics(props) {
  const client = useClient();
  const { session } = useSession();
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [mostVisited, setMostVisited] = useState([]);

  const getCards = async () => {
    setLoading(true);
    const { data, errors } = await client.fetch(`dashboards/kpis`);

    if (errors) {
      toast.error('Erro ao buscar informações dos cards.');
    } else {
      setCards(data.data);
    }
    setLoading(false);
  };

  const getMostVisited = async () => {
    const { data, errors } = await client.fetch(`route_logs/most_visited`);

    if (errors) {
      toast.error('Erro ao buscar rotas mais acessadas.');
    } else {
      setMostVisited(data.data);
    }
  };

  useEffect(() => {
    getCards();
    getMostVisited();
  }, []);

  const breadCrumbs = {
    title: 'Início',
    nav: [
      {
        route: '/',
        name: `Olá, ${session?.user?.name}`,
        isActive: true
      }
    ]
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <BreadCrumbs data={breadCrumbs} />

      <FrequentShortcutWidget mostVisited={mostVisited} />

      <div className="kpi-widget-wrapper">
        {cards?.map(card => (
          <KpiWidget
            title={card?.label}
            count={card?.value}
          />
        ))}
      </div>

      <div className="frequent-shortcut-widget-wrapper">
        <h3 className="frequent-shortcut-widget-wrapper__title">Relatórios mais acessados</h3>

        <div className="metrics-shortcut-wrapper">
          <MetricsShortcuts
            link="/relatorio/progresso-de-curso"
            icon="monitor"
            title="Progresso dos Cursos"
          />
          <MetricsShortcuts
            link="/relatorio/cadastro"
            icon="users"
            title="Cadastros"
          />
          <MetricsShortcuts
            link="/relatorio/funil-de-acessos"
            icon="funil"
            title="Funil de acessos"
          />
          <MetricsShortcuts
            link="/relatorio/acesso"
            icon="pointer"
            title="Mapeamento de acessos"
          />
        </div>
      </div>
    </>
  );
}

export default DashboardMetrics;
