import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { SimpleModal } from 'app/components/Modal';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import styles from '../styles.module.scss';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import Loader from 'app/components/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndScroll from 'app/components/endScroll';
import Avatar from 'app/components/Avatar';
import { useSession } from 'app/hooks/useSession';

export default function NewDuelModal({ open, setOpen, taxonomies, getSentRequests }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [students, setStudents] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedOpponent, setSelectedOpponent] = useState({});
  const debounceTimer = useRef(null);
  const client = useClient();

  const getStudents = async () => {
    let url = `users?page[number]=${pageNumber}&page[size]=15`;
    if (searchTerm.length > 0) {
      url += `&q[name_or_email_cont_any]=${searchTerm}`;
    }
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetStudents'));
    } else {
      if (data.length < 15) {
        setHasMore(false);
      }

      setStudents(prev => (pageNumber === 1 ? data : [...prev, ...data]));
    }
  };

  const createDuel = async newDuel => {
    const { error } = await client.mutate('duels', { invited_id: newDuel['invited-id'], parent_taxonomy_id: Number(newDuel.topic) });
    if (error) {
      if (error.message === 'already has an active duel with this user') {
        toast.error(t('toast.errorActiveDuel'));
      } else {
        toast.error(t('toast.errorCreateDuel'));
      }
    } else {
      getSentRequests();
      setOpen(false);
      toast.success(t('toast.successCreatedDuel'));
    }
  };

  const schema = Yup.object({
    topic: Yup.string().required('Campo obrigatório'),
    'invited-id': Yup.string().required('Campo obrigatório')
  });

  const duelForm = useFormik({
    initialValues: {
      topic: '',
      'invited-id': null
    },
    validationSchema: schema,
    onSubmit: values => {
      createDuel(values);
    }
  });

  const filteredStudents = students?.filter(student => student?.name?.toLowerCase().includes(searchTerm?.toLowerCase()));

  useEffect(() => {
    if (open) {
      getStudents();
    }
    if (open === false) {
      setSearchTerm('');
      setStudents([]);
      setPageNumber(1);
    }
  }, [open]);

  useEffect(() => {
    if (selectedOpponent) {
      duelForm.setFieldValue('invited-id', selectedOpponent.id);
    }
  }, [selectedOpponent]);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      if (searchTerm.length > 0) {
        setPageNumber(1);
        setHasMore(true);
      }
      setStudents([]);
      getStudents();
    }, 500);
  }, [searchTerm]);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
  }, []);

  useEffect(() => {
    getStudents();
  }, [pageNumber]);

  return (
    <SimpleModal
      show={open}
      onClose={() => {
        setOpen(false);
        setSelectedOpponent({});
        duelForm.resetForm();
      }}
      title="Novo duelo"
      contentClassName="simple-modal__content--lg"
    >
      <h2 className="modal__simple-title">Novo duelo</h2>
      <form onSubmit={duelForm.handleSubmit}>
        <FilterSelectionBox
          blankLabel={t('filter.blankLabelAll2')}
          label={`*${t('filter.selectTopic')}`}
          value={duelForm.values.topic}
          onChange={e => duelForm.setFieldValue('topic', e.target.value)}
          options={taxonomies}
        />
        {duelForm.touched.topic && duelForm.errors.topic && <span style={{ color: 'red' }}>{duelForm.errors.topic}</span>}

        <div className="filter-bar__row u-mt-3 u-mb-3">
          <label
            htmlFor="search"
            className="filter-bar__label"
          >
            *Desafie um amigo
          </label>
          <input
            aria-label="Buscar"
            className="form__control form__control--search-with-icon"
            placeholder={t('textsCommon.research')}
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        {/* {loadingStudents && <Loader />} */}
        {!duelForm?.values?.['invited-id'] && (
          <div
            className={styles['students-list']}
            id="scrollable"
          >
            <InfiniteScroll
              dataLength={filteredStudents.length}
              hasMore={hasMore}
              next={() => setPageNumber(pageNumber + 1)}
              loader={<Loader />}
              endMessage={<EndScroll />}
              scrollableTarget="scrollable"
            >
              {filteredStudents?.map(student => (
                <div
                  className={styles['challenged-card']}
                  key={student?.id}
                >
                  <div className={styles['challenged-card__student']}>
                    <Avatar src={student?.image.url} />
                    <span>{student?.name}</span>
                  </div>
                  <span
                    className={styles['challenged-card__invite-btn']}
                    onClick={() => setSelectedOpponent({ id: student?.id, name: student?.name, image: student?.image?.url })}
                  >
                    Convidar
                  </span>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        )}
        {duelForm?.values?.['invited-id'] && (
          <>
            <div
              className={`${styles['challenged-card']} ${styles['challenged-card--selected']}`}
              key={duelForm.values.challenged?.id}
            >
              <div className={styles['challenged-card__student']}>
                <Avatar
                  src={selectedOpponent?.image}
                  className="avatar avatar--sm"
                />
                <span>{selectedOpponent?.name}</span>
              </div>
              <span
                className={styles['challenged-card__invite-btn']}
                onClick={() => setSelectedOpponent({})}
              >
                Remover convite
              </span>
            </div>
            <button className="btn btn--primary btn--wide u-mt-2">Novo duelo</button>
          </>
        )}
      </form>
    </SimpleModal>
  );
}
