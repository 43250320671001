import React, { useEffect, useState } from 'react';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from 'app/screens/Login/Login.module.scss';
import Button, { ButtonWrapper } from 'app/components/Button/Button';
import { FormControl, FormField, FormFieldGrade, FormFieldGradeWrapper, FormLabel, FormRow, FormStep, FormFeedback, FormCustomSelect, FormTerms } from 'app/components/Form/Form';
import PasswordStrength from 'app/components/PasswordStrength';
import { useParams, navigate } from '@reach/router';
import { useMask } from '@react-input/mask';
import { LoginLayout } from 'app/screens/Login/Login';
import { validateCPF } from 'app/utils/validateCPF';
import toast from 'react-hot-toast';
import api from 'app/services/api';
import { mapToFormFields } from 'app/services/mapSignUpStudent';
import { createStudent, formatStudentData } from 'app/services/auth';

export const checkEmail = async email => {
  try {
    return await api.post(`/users/check_email?email=${email}`);
  } catch (error) {
    return error.response;
  }
};

export const checkStudentCpfOrRa = async data => {
  try {
    if (data.cpf) {
      return await api.post(`/canaleducacao/alunos`, { data: { attributes: { cpf: data.cpf } } });
    } else {
      return await api.post(`/canaleducacao/alunos`, { data: { attributes: { ra: data.ra } } });
    }
  } catch (error) {
    return error.response;
  }
};

const schoolSituationSchema = Yup.object().shape({
  schoolSituation: Yup.string().required('Campo obrigatório')
});

const gradeSchema = Yup.object().shape({
  grade: Yup.string().required('Campo obrigatório')
});

const documentTypeSchema = Yup.object().shape({
  documentType: Yup.string().required('Campo obrigatório')
});

const raSchema = Yup.object().shape({
  ra: Yup.string().required('Campo obrigatório')
});

const cpfSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF Inválido', value => validateCPF(value))
});

const nameSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório')
});

const emailSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'E-mails não coincidem')
    .email('E-mail inválido')
    .required('Campo obrigatório')
});

const passwordSchema = Yup.object().shape({
  password: Yup.string().required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não coincidem')
    .required('Campo obrigatório')
});

const cellPhoneSchema = Yup.object().shape({
  cellPhone: Yup.string()
    .required('Campo obrigatório')
    .matches(/^\([1-9]{2}\) [9]{1}[0-9]{4}-[0-9]{4}$/, 'Telefone inválido')
});

const stateSchema = Yup.object().shape({
  state: Yup.string().required('Campo obrigatório'),
  municipality: Yup.string().required('Campo obrigatório')
});

const schoolSchema = Yup.object().shape({
  school: Yup.string().required('Campo obrigatório'),
  gradeAndShift: Yup.string().required('Campo obrigatório'),
  class: Yup.string().required('Campo obrigatório')
});

const termsSchema = Yup.object().shape({
  terms: Yup.string().required('É necessário aceitar os termos.')
});

export default function StudentSignUp() {
  const [formValues, setFormValues] = useState({});
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordIsStrong, setPasswordIsStrong] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [gradeAndShiftOptions, setGradeAndShiftOptions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedGradeAndShift, setSelectedGradeAndShift] = useState(null);
  const [errors, setErrors] = useState({});

  const params = useParams();
  const link = params.link;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const ssoClient = process.env.REACT_APP_SSO_CLIENT_URL;

  const uregisteredParam = urlSearchParams.get('unregistered');
  const doneParam = urlSearchParams.get('done');

  if (uregisteredParam && !localStorage.getItem('unregistered')) localStorage.setItem('unregistered', uregisteredParam);
  if (doneParam && !localStorage.getItem('done')) localStorage.setItem('done', doneParam);

  const uregistered = localStorage.getItem('unregistered') ?? false;
  const done = localStorage.getItem('done') ?? false;

  useEffect(async () => {
    async function fetchStates() {
      const response = await api.get('/states ');
      const states = response.data.map(state => {
        return { value: state.attributes.id, label: state.attributes.name };
      });
      return states;
    }

    async function fetchInstitutions() {
      const response = await api.get(`/institutions`);
      const institutions = response.data.map(institution => {
        return { value: institution.attributes.id, label: institution.attributes.name };
      });
      return institutions;
    }

    async function fetchCourses() {
      const response = await api.get(`/courses/simple?filter[is_public]=false`);
      const courses = response.data.map(course => {
        return { value: course.attributes.id, label: course.attributes.title };
      });
      return courses;
    }

    Promise.all([fetchStates(), fetchInstitutions(), fetchCourses()]).then(([states, institutions, courses]) => {
      setStateOptions(states);
      setSchoolOptions(institutions);
      setGradeAndShiftOptions(courses);
    });
  }, []);

  useEffect(async () => {
    async function fetchMunicipalities() {
      const response = await api.get(`/municipalities?filter[state_id]=${selectedState}`);
      const municipalities = response.data.map(municipality => {
        return { value: municipality.attributes.id, label: municipality.attributes.name };
      });
      return municipalities;
    }

    if (selectedState) {
      const municipalities = await fetchMunicipalities();
      setMunicipalityOptions(municipalities);
    }
  }, [selectedState]);

  useEffect(async () => {
    async function fetchClasses() {
      const response = await api.get(`/classrooms?filter[institution_id]=${selectedSchool}&filter[course_id]=${selectedGradeAndShift}`);
      const classes = response.data.map(classe => {
        return { value: classe.attributes.id, label: classe.attributes.title };
      });
      return classes;
    }

    if (selectedSchool && selectedGradeAndShift) {
      const classes = await fetchClasses();
      setClassOptions(classes);
    }
  }, [selectedSchool, selectedGradeAndShift]);

  const { formStep } = useParams();
  const cfpRef = useMask({
    mask: '___.___.___-__',
    replacement: { _: /\d/ }
  });
  const cellPhoneRef = useMask({
    mask: '(__) _____-____',
    replacement: { _: /\d/ }
  });
  const passwordRef = React.createRef();
  const confirmPasswordRef = React.createRef();

  function handleFormValues(values) {
    setFormValues({ ...formValues, ...values });
  }

  const handleNext = async (values, actions) => {
    handleFormValues(values);
    actions.setSubmitting(false);
    setErrors({});

    if (!formStep) {
      if (values.schoolSituation === 'registered') navigate('/criar-uma-conta/aluno/serie');
      if (values.schoolSituation === 'done') navigate('/criar-uma-conta/aluno/cpf');
    }
    if (formStep === 'serie') {
      navigate('/criar-uma-conta/aluno/consulta-de-dados');
    }
    if (formStep === 'consulta-de-dados') {
      if (values.documentType === 'cpf') navigate('/criar-uma-conta/aluno/cpf');
      handleFormValues();
      if (values.documentType === 'ra') navigate('/criar-uma-conta/aluno/registro-academico');
    }
    if (formStep === 'registro-academico') {
      const ra = values.ra.replace(/\D/g, '');
      const response = await checkStudentCpfOrRa({ ra });
      if (response?.data.attributes?.ALUNO) handleFormValues(mapToFormFields(response));
      else handleFormValues({ ra });
      navigate('/criar-uma-conta/aluno/cpf');
    }
    if (formStep === 'cpf') {
      const cpf = values.cpf.replace(/\D/g, '');
      const response = await checkStudentCpfOrRa({ cpf });

      if (response?.data.attributes?.ALUNO) handleFormValues(mapToFormFields(response));
      else handleFormValues({ cpf });
      navigate('/criar-uma-conta/aluno/nome');
    }
    if (formStep === 'nome') {
      navigate('/criar-uma-conta/aluno/email');
    }
    if (formStep === 'email') {
      const email = values.email;
      const response = await checkEmail(email);

      const { found } = response;

      if (found) {
        actions.setFieldError('email', 'Este email já está cadastrado. Termine seu cadastro com um email diferente ou solicite nova senha para este email.');
        actions.setFieldError('confirmEmail', 'Este email já está cadastrado. Termine seu cadastro com um email diferente ou solicite nova senha para este email.');
        return;
      }
      navigate('/criar-uma-conta/aluno/senha');
    }
    if (formStep === 'senha') {
      navigate('/criar-uma-conta/aluno/telefone');
    }
    if (formStep === 'telefone') {
      navigate('/criar-uma-conta/aluno/estado');
    }
    if (formStep === 'estado') {
      if (uregistered || done || link !== 'aluno') {
        navigate('/criar-uma-conta/aluno/termos');
      }
      navigate('/criar-uma-conta/aluno/escola');
    }
    if (formStep === 'escola') {
      navigate('/criar-uma-conta/aluno/termos');
    }
    if (formStep === 'termos') {
      toast.loading('Enviando dados...');
      const studentFormated = formatStudentData(formValues);
      try {
        let response;
        if (link !== 'aluno') {
          response = await createStudent({ ...studentFormated, link_key: link });
        } else {
          response = await createStudent(studentFormated);
        }
        toast.dismiss();
        if (response.data) {
          toast.success('Cadastro realizado com sucesso');
          navigate('/criar-uma-conta/aluno/conta-criada');
        }
      } catch (error) {
        toast.dismiss();
        toast.error('Erro ao enviar dados. Tente novamente mais tarde.');
      } finally {
        if (uregistered) localStorage.removeItem('unregistered');
        if (done) localStorage.removeItem('unregistered');
        localStorage.removeItem('done');
        actions.setSubmitting(false);
      }
    }
  };

  let cpfBackBtn;
  if (done) cpfBackBtn = '/criar-uma-conta/aluno';
  if (formValues.schoolSituation !== 'done') cpfBackBtn = '/criar-uma-conta/aluno/consulta-de-dados';
  if (formValues.schoolSituation !== 'done' && formValues.documentType === 'ra') cpfBackBtn = '/criar-uma-conta/aluno/registro-academico';

  return (
    <LoginLayout>
      <FormStep show={!formStep}>
        <Formik
          initialValues={{ schoolSituation: formValues.schoolSituation ?? '' }}
          validationSchema={schoolSituationSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <h1
                  className={styles.title}
                  id="my-radio-group"
                >
                  Qual sua situação escolar?
                </h1>

                <FormRow
                  role="group"
                  aria-labelledby="my-radio-group"
                  style={{ gap: 24 }}
                >
                  <FormField>
                    <Field
                      type="radio"
                      name="schoolSituation"
                      value="registered"
                    />
                    Sou estudante e estou matriculado em uma escola
                  </FormField>
                  <FormField>
                    <Field
                      type="radio"
                      name="schoolSituation"
                      value="unregistered"
                    />
                    Quero estudar mas não estou matriculado em nenhuma escola
                  </FormField>
                  <FormField>
                    <Field
                      type="radio"
                      name="schoolSituation"
                      value="done"
                    />
                    Já conclui o Ensino Médio e quero revisar matérias
                  </FormField>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/login"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.schoolSituation}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'serie'}>
        <Formik
          initialValues={{ grade: formValues.grade ?? '' }}
          validationSchema={gradeSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <h1
                  className={styles.title}
                  id="my-radio-group"
                >
                  Qual sua situação escolar?
                </h1>

                <FormRow aria-labelledby="my-radio-group">
                  <FormFieldGradeWrapper>
                    <FormFieldGrade modifiers={['firstGrade']}>
                      <Field
                        type="radio"
                        name="grade"
                        value="1"
                      />
                      <span>1º Série</span>
                    </FormFieldGrade>
                    <FormFieldGrade modifiers={['secondGrade']}>
                      <Field
                        type="radio"
                        name="grade"
                        value="2"
                      />
                      <span>2º Série</span>
                    </FormFieldGrade>
                    <FormFieldGrade modifiers={['thirdGrade']}>
                      <Field
                        type="radio"
                        name="grade"
                        value="3"
                      />
                      <span>3º Série</span>
                    </FormFieldGrade>
                    <FormFieldGrade modifiers={['eja']}>
                      <Field
                        type="radio"
                        name="grade"
                        value="eja"
                      />
                      <span>EJA</span>
                    </FormFieldGrade>
                  </FormFieldGradeWrapper>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.grade}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'consulta-de-dados'}>
        <Formik
          initialValues={{ documentType: formValues.documentType ?? '' }}
          validationSchema={documentTypeSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1
                    className={styles.title}
                    id="my-radio-group"
                  >
                    Vamos consultar seu dados na <strong>Rede SEDUC</strong>
                  </h1>

                  <p className={styles.description}>Escolha um dos dados abaixo para inserir</p>
                </div>

                <FormRow
                  role="group"
                  aria-labelledby="my-radio-group"
                  style={{ gap: 24 }}
                >
                  <FormField>
                    <Field
                      type="radio"
                      name="documentType"
                      value="cpf"
                    />
                    CPF
                  </FormField>
                  <FormField>
                    <Field
                      type="radio"
                      name="documentType"
                      value="ra"
                    />
                    RA (registro acadêmico)
                  </FormField>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  style={{ minWidth: 134 }}
                  onClick={() => {
                    if (ssoClient) {
                      if (uregistered || done) window.location.href = `${ssoClient}/criar-uma-conta/aluno`;
                      else window.location.href = window.location.href = `${ssoClient}/criar-uma-conta/aluno/serie`;
                    } else {
                      window.location.href = '/login';
                    }
                    return;
                  }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.documentType}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'registro-academico'}>
        <Formik
          initialValues={{ ra: formValues.ra ?? '' }}
          validationSchema={raSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>
                    Vamos consultar seu dados na <strong>Rede SEDUC</strong>
                  </h1>

                  <p className={styles.description}>Digite os números pedidos</p>
                </div>

                <FormRow>
                  <FormLabel htmlFor="ra">RA (registro acadêmico)</FormLabel>
                  <FormControl
                    id="ra"
                    name="ra"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.ra}
                    invalid={props.errors.ra && props.touched.ra}
                    placeholder="000000"
                  />
                  <ErrorMessage name="ra">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to={'/criar-uma-conta/aluno/consulta-de-dados'}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.ra}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'cpf'}>
        <Formik
          initialValues={{ cpf: formValues.cpf ?? '' }}
          validationSchema={cpfSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>
                    Vamos consultar seu dados na <strong>Rede SEDUC</strong>
                  </h1>

                  <p className={styles.description}>Digite os números pedidos</p>
                </div>

                <FormRow>
                  <FormLabel htmlFor="cpf">CPF</FormLabel>
                  <FormControl
                    id="cpf"
                    name="cpf"
                    type="text"
                    // onChange={props.handleChange}
                    // onBlur={props.handleBlur}
                    value={props.values.cpf}
                    invalid={props.errors.cpf && props.touched.cpf}
                    placeholder="000.000.000-00"
                    ref={cfpRef}
                  />
                  <ErrorMessage name="cpf">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to={cpfBackBtn}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.cpf}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'nome'}>
        <Formik
          initialValues={{ cpf: formValues.cpf, name: formValues.name ?? '' }}
          validationSchema={nameSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Siga preenchendo os dados básicos</h1>

                  <FormRow disabled={true}>
                    <FormLabel htmlFor="cpf">CPF</FormLabel>
                    <FormControl
                      id="cpf"
                      name="cpf"
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.cpf}
                      maxLength={11}
                      placeholder="000.000.000-00"
                    />
                  </FormRow>
                  <FormRow>
                    <FormLabel htmlFor="name">*Nome</FormLabel>
                    <FormControl
                      id="name"
                      name="name"
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      placeholder="Insira seu nome completo"
                    />
                  </FormRow>
                </div>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/cpf"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.name}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'email'}>
        <Formik
          initialValues={{ email: formValues.email, confirmEmail: formValues.confirmEmail ?? '' }}
          validationSchema={emailSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Preencha com seu email</h1>
                  <p className={`${styles.description} ${styles.box} `}>Toda a comunicação da plataforma será feita pelo email que você cadastrar. Anote-o para não esquecer.</p>
                </div>

                <FormRow>
                  <FormLabel htmlFor="email">*Email</FormLabel>
                  <FormControl
                    id="email"
                    name="email"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    invalid={props.errors.email && props.touched.email}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="email">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                  {errors?.email && <ErrorMessage name="email">{<FormFeedback invalid={true}>{errors.email}</FormFeedback>}</ErrorMessage>}
                </FormRow>
                <FormRow>
                  <FormLabel htmlFor="confirmEmail">*Confirme seu email</FormLabel>
                  <FormControl
                    id="confirmEmail"
                    name="confirmEmail"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.confirmEmail}
                    invalid={props.errors.confirmEmail && props.touched.confirmEmail}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="confirmEmail">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                  {errors?.email && <ErrorMessage name="email">{<FormFeedback invalid={true}>{errors.email}</FormFeedback>}</ErrorMessage>}
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/nome"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.email || !props.values.confirmEmail}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'senha'}>
        <Formik
          initialValues={{ password: formValues.password ?? '', showPassword: formValues.showPassword ?? false, confirmPassword: formValues.confirmPassword ?? '', showConfirmPassword: formValues.showConfirmPassword ?? false }}
          validationSchema={passwordSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Crie uma senha para cadastro.</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="password">*Senha</FormLabel>
                  <PasswordStrength
                    show={showPasswordStrength}
                    password={props.values.password}
                    validPassword={setPasswordIsStrong}
                  >
                    <FormControl
                      id="password"
                      name="password"
                      type={props.values.showPassword ? 'text' : 'password'}
                      onChange={props.handleChange}
                      onFocus={() => {
                        setShowPasswordStrength(true);
                        passwordRef.current.removeAttribute('readOnly');
                      }}
                      onBlur={() => {
                        setShowPasswordStrength(false);
                        passwordRef.current.setAttribute('readOnly', '');
                      }}
                      value={props.values.password}
                      invalid={props.errors.password && props.touched.password}
                      placeholder="Insira sua senha"
                      ref={passwordRef}
                      autoComplete="off"
                      readOnly
                    />
                  </PasswordStrength>
                  <FormField>
                    <Field
                      type="checkbox"
                      name="showPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="password">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>

                <FormRow>
                  <FormLabel htmlFor="confirmPassword">*Confirme sua senha</FormLabel>
                  <FormControl
                    id="confirmPassword"
                    name="confirmPassword"
                    type={props.values.showConfirmPassword ? 'text' : 'password'}
                    onChange={props.handleChange}
                    onFocus={() => confirmPasswordRef.current.removeAttribute('readOnly')}
                    onBlur={() => confirmPasswordRef.current.setAttribute('readOnly', '')}
                    value={props.values.confirmPassword}
                    invalid={props.errors.confirmPassword && props.touched.confirmPassword}
                    placeholder="Insira sua senha"
                    ref={confirmPasswordRef}
                    autoComplete="off"
                    readOnly
                  />
                  <FormField>
                    <Field
                      type="checkbox"
                      name="showConfirmPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="confirmPassword">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/email"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.password || !props.values.confirmPassword || !passwordIsStrong}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'telefone'}>
        <Formik
          initialValues={{ cellPhone: formValues.cellPhone ?? '' }}
          validationSchema={cellPhoneSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Insira o número do seu telefone celular</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="cellPhone">Celular com DDD</FormLabel>
                  <FormControl
                    id="cellPhone"
                    name="cellPhone"
                    type="tel"
                    value={props.values.cellPhone}
                    invalid={props.errors.cellPhone && props.touched.cellPhone}
                    placeholder="(00) 00000-0000"
                    ref={cellPhoneRef}
                  />
                  <ErrorMessage name="cellPhone">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/senha"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.cellPhone}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'estado'}>
        <Formik
          initialValues={{ state: formValues.state ?? '', municipality: formValues.municipality ?? '' }}
          validationSchema={stateSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Selecione o seu estado e município</h1>
                </div>

                <FormRow>
                  <FormLabel>*Estado</FormLabel>
                  <FormCustomSelect
                    values={props.values}
                    name="state"
                    options={stateOptions}
                    onChange={setSelectedState}
                    placeholder="Pesquise pelo estado"
                  />
                </FormRow>

                <FormRow disabled={!props.values.state || props.values.state === '' || !municipalityOptions.length}>
                  <FormLabel>*Município</FormLabel>
                  <FormCustomSelect
                    values={props.values}
                    name="municipality"
                    options={municipalityOptions}
                    disabled={!props.values.state || props.values.state === '' || !municipalityOptions.length}
                    placeholder="Pesquise pelo município"
                  />
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/telefone"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.state || !props.values.municipality}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'escola'}>
        <Formik
          initialValues={{ school: formValues.school ?? '', gradeAndShift: formValues.gradeAndShift ?? '', class: formValues.class ?? '' }}
          validationSchema={schoolSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Insira seus dados escolares</h1>
                </div>

                <FormRow>
                  <FormLabel>*Unidade escolar</FormLabel>
                  <FormCustomSelect
                    values={props.values}
                    name="school"
                    options={schoolOptions}
                    onChange={setSelectedSchool}
                    placeholder="Pesquise por sua escola"
                  />
                </FormRow>

                <FormRow disabled={!props.values.school}>
                  <FormLabel>*Série e turno</FormLabel>
                  <FormCustomSelect
                    values={props.values}
                    name="gradeAndShift"
                    options={gradeAndShiftOptions}
                    onChange={setSelectedGradeAndShift}
                    placeholder="Selecione uma série e turno"
                  />
                </FormRow>

                <FormRow disabled={!props.values.gradeAndShift}>
                  <FormLabel>*Turma</FormLabel>
                  <FormCustomSelect
                    values={props.values}
                    name="class"
                    options={classOptions}
                    placeholder="Selecione sua turma"
                  />
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/estado"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.school || !props.values.gradeAndShift || !props.values.class}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'termos'}>
        <Formik
          initialValues={{ terms: formValues.terms ?? true }}
          validationSchema={termsSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Termos de uso e política de privacidade</h1>
                </div>

                <FormRow>
                  <FormTerms />
                </FormRow>

                <FormRow>
                  <FormField round>
                    <Field
                      type="checkbox"
                      name="terms"
                    />
                    <span>
                      Ao clicar em “Criar conta”, você concorda com os{' '}
                      <a
                        href="/termos-de-uso"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        termos de uso
                      </a>{' '}
                      e{' '}
                      <a
                        href="/politica-de-privacidade"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        política de privacidade
                      </a>
                    </span>
                  </FormField>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/criar-uma-conta/aluno/escola"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={!props.values.terms}
                >
                  Criar minha conta
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'conta-criada'}>
        <div className={styles.body}>
          <div className={styles.textGroup}>
            <h1 className={styles.title}>Conta foi criada!</h1>
            <p className={styles.description}>Enviamos um email para você. Acesse-o e ative sua conta.</p>
          </div>
        </div>
      </FormStep>
    </LoginLayout>
  );
}
