import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import styles from './Dialog.module.scss';
import Icon, { IconWrapper } from 'app/components/Icon/Icon';

function DialogTitle(props) {
  const { children } = props;

  return <h3 className={styles.title}>{children}</h3>;
}

function DialogDescription(props) {
  const { children } = props;

  return <p className={styles.description}>{children}</p>;
}

function Dialog(props) {
  const { show, onClose, children, hideCloseButton } = props;

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  useEffect(() => {
    if (onClose) {
      document.body.addEventListener('keydown', closeOnEscapeKeyDown);
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        document.body.style.overflow = 'visible';
      };
    }
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  return ReactDOM.createPortal(
    <div
      className={show ? `${styles.dialog} ${styles.active}` : styles.dialog}
      onClick={onClose}
    >
      <div
        className={styles.content}
        onClick={event => event.stopPropagation()}
      >
        {!hideCloseButton && (
          <IconWrapper
            onClick={onClose}
            style={{ position: 'absolute', top: 12, right: 12, cursor: 'pointer' }}
          >
            <Icon>
              <FiX />
            </Icon>
          </IconWrapper>
        )}

        {children}
      </div>
    </div>,
    document.getElementById('modal_root')
  );
}

export { Dialog as default, DialogTitle, DialogDescription };
