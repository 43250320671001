import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useSession } from '../hooks/useSession';
import { useFormik } from 'formik';
import TextareaAutosize from 'react-autosize-textarea';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import promiseRequest from 'app/utils/promiseToast';
import Loader from './loader';
import Select from 'react-select';

function EventForm(props) {
  const { t } = useTranslation();
  const { onClose, events, editingEvent, setEvents } = props;
  const client = useClient();
  const { session } = useSession();
  const { promiseToast, isSubmitting } = promiseRequest();
  const profiles = session.user?.profileNames || (session.user && session.user['profile-names']) || [];
  const eventAlternatives = [
    { id: 2, name: t('events.toggleClass') },
    { id: 1, name: t('events.toggleCourse') }
  ];

  if (profiles.includes('Administrador')) {
    eventAlternatives.push({ id: 0, name: t('events.togglePublic') });
  }

  const [imageLabel, setImageLabel] = useState();
  const [selectedAlternative, setSelectedAlternative] = useState(eventAlternatives[0]?.id);
  const [relationshipsLoading, setRelationshipsLoading] = useState(true);
  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: [],
    contents: []
  });
  const [selectedCourse, setSelectedCourse] = useState(true);
  const [selectedRegional, setSelectedRegional] = useState('');
  const [institutions, setInstitutions] = useState();
  const [filterInstitutions, setFilterInstitutions] = useState([]);
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);

  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${String(i).padStart(2, '0')}:00`);
  }

  useEffect(() => {
    if (editingEvent) {
      if (editingEvent['course-id']) {
        setSelectedAlternative(1);
      } else if (editingEvent['classroom-id']) {
        setSelectedAlternative(2);
      }
    }
  }, [editingEvent]);

  const form = useFormik({
    initialValues: {
      title: editingEvent?.title || '',
      description: editingEvent?.description || '',
      image: '',
      regionals: null,
      institution: null,
      url: editingEvent?.url || '',
      'initial-date': editingEvent ? editingEvent['initial-date'] : '',
      'initial-time': editingEvent ? editingEvent['initial-time'] : '00:00',
      'end-time': editingEvent ? editingEvent['end-time'] : '00:00',
      'is-public': editingEvent ? editingEvent['is-public'] : profiles.includes('Administrador'),
      'course-id': editingEvent ? editingEvent['course-id'] : null,
      'classroom-id': editingEvent ? editingEvent['classroom-id'] : null
    },
    validationSchema: yup.object({
      'course-id': yup
        .string()
        .nullable()
        .when('selectedAlternative', {
          is: () => selectedAlternative === 1,
          then: yup
            .string()
            .nullable()
            .test('test', t('warning.requiredField'), value => value && value !== '0'),
          otherwise: yup.string().nullable().notRequired()
        }),
      'classroom-id': yup
        .mixed()
        .nullable()
        .when('selectedAlternative', {
          is: () => selectedAlternative === 2,
          then: yup
            .mixed()
            .nullable()
            .test('test', t('warning.requiredField'), value => value && value !== '0'),
          otherwise: yup.mixed().nullable().notRequired()
        })
    }),
    onSubmit: () => submitForm()
  });

  useEffect(() => {
    if (relationships.regionals && editingEvent) {
      const selectedRegional = relationships?.regionals?.find(item => item.id === editingEvent?.['regional-id']);
      form.setFieldValue('regionals', { label: selectedRegional ? selectedRegional.name : t('filter.selectRegionals'), value: selectedRegional?.id });
    }
    if (institutions && editingEvent) {
      const selectedInstitution = institutions?.find(item => item.id === editingEvent?.['institution-id']);
      form.setFieldValue('institution', { label: selectedInstitution ? selectedInstitution.name : t('filter.selectInstitution'), value: selectedInstitution?.id });
    }
    if (relationships.classrooms && editingEvent) {
      const selectedClassroom = relationships?.classrooms?.find(item => item.id === editingEvent?.['classroom-id']);
      form.setFieldValue('classroom-id', { label: selectedClassroom ? courseName(selectedClassroom) + ' - ' + selectedClassroom.title : t('filter.labelSelectTheClassroom'), value: selectedClassroom?.id });
    }
  }, [editingEvent, relationships, institutions]);

  useEffect(() => {
    getRelationships();
  }, []);

  useEffect(() => {
    if (selectedRegional) {
      const filteredInstitutions = relationships?.regionals.find(item => item.id === selectedRegional)?.institutions || [];
      setFilterInstitutions(filteredInstitutions);
      form.setFieldValue('institution', null);
    } else {
      setFilterInstitutions(institutions);
    }
  }, [selectedRegional, relationships]);

  useEffect(() => {
    if (filterInstitutions) {
      const institutionId = form.values.institution?.value;
      const classrooms = relationships?.classrooms.filter(item => item?.['institution-id'] === institutionId) || [];
      setFilteredClassrooms(classrooms);
    } else {
      setFilteredClassrooms([]);
    }
  }, [filterInstitutions, relationships, form.values.institution]);

  const getRelationships = () => {
    const coursesFetch = client.fetch('courses/simple?filter[is_public]=false');
    const classroomsFetch = client.fetch('classrooms/simple');
    const regionalsFetch = client.fetch(`regionals`);
    const institutionsFetch = client.fetch(`institutions`);
    Promise.all([coursesFetch, classroomsFetch, regionalsFetch, institutionsFetch]).then(([coursesResponse, classroomsResponse, regionalsResponse, institutionsResponse]) => {
      setInstitutions(institutionsResponse?.data || []);
      setRelationships({
        courses: coursesResponse?.data || [],
        classrooms: classroomsResponse?.data || [],
        regionals: regionalsResponse?.data || []
      });
      setRelationshipsLoading(false);
    });
  };

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0].name);

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    form.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  const submitForm = () => {
    const auxValues = { ...form.values };

    if (selectedAlternative === 2) {
      auxValues['classroom-id'] = auxValues['classroom-id'].value;
    }

    const today = moment();
    const eventDate = moment(form.values?.['initial-date']);
    const avaliableDate = eventDate.diff(today, 'days') <= 365;

    const url = editingEvent ? ['events', editingEvent.id] : 'events';

    if (!avaliableDate) {
      toast.error(t('toast.errorCreateEventDate'));
    }
    delete auxValues.regionals;
    delete auxValues.institution;

    promiseToast({
      url,
      request: auxValues,
      successText: editingEvent ? t('events.toastEventSuccessSaved') : t('events.toastEventSuccessCreated'),
      errorText: t('events.toastEventErrorSaved')
    }).then(data => {
      if (editingEvent) {
        const newEvents = events.map(e => (e.id === editingEvent.id ? data : e));
        setEvents(newEvents);
        onClose();
      } else {
        setEvents([...events, data]);
        onClose();
      }
    });
  };

  const courseName = classroom => {
    const course = relationships.courses.find(item => item.id === classroom['course-id']);
    return course?.title;
  };

  return (
    <form
      action="post"
      className="form"
      onSubmit={form.handleSubmit}
    >
      <h2 className="modal__simple-title">{t('events.titleCreateNewEvent')}</h2>

      {!profiles.includes('Aluno') && (
        <div className="form__row">
          <div
            className="btn-group"
            role="group"
            aria-label="Escolha o tipo de evento"
          >
            {eventAlternatives.map((alternative, index) => (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  name="eventType"
                  id={alternative.id}
                  autoComplete="off"
                  checked={alternative.id === selectedAlternative}
                  onChange={() => {
                    form.setFieldValue('is-public', true);
                    form.setFieldValue('course-id', null);
                    form.setFieldValue('classroom-id', null);
                    setSelectedAlternative(alternative.id);
                  }}
                />
                <label
                  htmlFor={alternative.id}
                  className="btn btn--outline"
                >
                  {alternative.name}
                </label>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {!relationshipsLoading && selectedAlternative === 1 && (
        <div className="form__row form__row--hr">
          <label
            className="form__label"
            htmlFor="courses"
          >
            Curso
          </label>

          <select
            className="form__select"
            name="courses"
            id="courses"
            aria-label="Escolha o curso"
            value={form.values['course-id']}
            onBlur={form.handleBlur}
            onChange={e => {
              setSelectedCourse(e.target.value == '0');
              form.setFieldValue('course-id', e.target.value);
              form.setFieldValue('is-public', false);
            }}
          >
            <option
              key={0}
              id={0}
              value={0}
            >
              {t('filter.labelSelectTheCourse')}
            </option>
            {relationships.courses.map(c => (
              <option
                key={c.id}
                value={c.id}
              >
                {c.title}
              </option>
            ))}
          </select>
          {form.touched['course-id'] && form.errors['course-id'] && selectedCourse && <span style={{ color: 'red' }}>{form.errors['course-id']}</span>}
        </div>
      )}

      {relationshipsLoading && selectedAlternative === 2 ? (
        <Loader />
      ) : (
        <>
          {!relationshipsLoading && selectedAlternative === 2 && (
            <>
              {relationships?.regionals?.length > 1 && (
                <div className="form__row">
                  <label className="form__label">Regional</label>

                  <Select
                    value={form.values.regionals}
                    openMenuOnFocus
                    options={[
                      { value: null, label: t('filter.selectRegionals') },
                      ...relationships?.regionals?.map(item => {
                        return { value: item.id, label: item.name };
                      })
                    ]}
                    className="react-multi-select filter-bar__multi-select u-w-100"
                    classNamePrefix="react-multi-select"
                    placeholder={relationships?.regionals?.length === 0 ? t('loader.loading2') : t('filter.selectRegionals')}
                    noOptionsMessage={() => 'Sem opções'}
                    components={{
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null
                    }}
                    onChange={e => {
                      form.setFieldValue('regionals', e);
                      setSelectedRegional(e.value);
                    }}
                  />
                </div>
              )}

              <div className="form__row">
                <label className="form__label">Instituição</label>

                {filterInstitutions?.length > 1 && (
                  <Select
                    value={form.values.institution}
                    openMenuOnFocus
                    options={[
                      { value: null, label: t('filter.selectInstitution') },
                      ...filterInstitutions?.map(item => {
                        return { value: item.id, label: item.name };
                      })
                    ]}
                    className="react-multi-select filter-bar__multi-select u-w-100"
                    classNamePrefix="react-multi-select"
                    placeholder={filterInstitutions?.length === 0 ? t('loader.loading2') : t('filter.selectInstitution')}
                    noOptionsMessage={() => 'Sem opções'}
                    components={{
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null
                    }}
                    onChange={e => {
                      form.setFieldValue('institution', e);
                    }}
                  />
                )}
              </div>

              <div className="form__row form__row--hr">
                <label className="form__label">Turma</label>

                <Select
                  value={form.values['classroom-id']}
                  openMenuOnFocus
                  options={
                    filterInstitutions?.length > 1
                      ? [
                          { value: null, label: t('filter.labelSelectTheClassroom') },
                          ...filteredClassrooms.map(c => {
                            return { value: c.id, label: `${courseName(c)} - ${c.title}` };
                          })
                        ]
                      : [
                          { value: '', label: t('filter.labelSelectTheClassroom') },
                          ...relationships.classrooms.map(c => {
                            return { value: c.id, label: `${courseName(c)} - ${c.title}` };
                          })
                        ]
                  }
                  className="react-multi-select filter-bar__multi-select u-w-100"
                  classNamePrefix="react-multi-select"
                  placeholder={t('filter.labelSelectTheClassroom')}
                  noOptionsMessage={() => 'Sem opções'}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  onChange={e => {
                    form.setFieldValue('classroom-id', e);
                    form.setFieldValue('is-public', false);
                  }}
                  isDisabled={!form.values.institution}
                />
                {form.touched['classroom-id'] && form.errors['classroom-id'] && <span style={{ color: 'red' }}>{form.errors['classroom-id']}</span>}
              </div>
            </>
          )}
        </>
      )}

      <div className="form__row form__row--columns">
        <div className="form__col">
          <label
            className="form__label"
            htmlFor="initial-date"
          >
            {t('events.eventDay')}
          </label>
          <input
            className="form__control"
            id="initial-date"
            name="initial-date"
            type="date"
            onChange={form.handleChange}
            defaultValue={form.values['initial-date']}
          />
        </div>

        <div className="form__col">
          <div className="form__columns">
            <div className="form__col">
              <label
                className="form__label"
                htmlFor="initial-time"
              >
                Início
              </label>

              <select
                className="form__select"
                name="initial-time"
                id="initial-time"
                aria-label="Hora início"
                onChange={form.handleChange}
                defaultValue={form.values['initial-time']}
              >
                {hours.map(i => (
                  <option
                    key={i}
                    value={i}
                  >
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <div className="form__col">
              <label
                className="form__label"
                htmlFor="end-time"
              >
                Fim
              </label>

              <select
                className="form__select"
                name="end-time"
                id="end-time"
                aria-label="Hora fim"
                onChange={form.handleChange}
                defaultValue={form.values['end-time']}
              >
                {hours.map(i => (
                  <option
                    key={i}
                    value={i}
                  >
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
          {t('events.eventTitle')}
        </label>
        <input
          className="form__control"
          id="title"
          name="title"
          type="text"
          placeholder={t('events.placeholderEventDescription')}
          onChange={form.handleChange}
          defaultValue={form.values.title}
        />
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          {t('tasks.description')}
        </label>

        <TextareaAutosize
          id="description"
          name="description"
          placeholder={t('events.placeholderAboutEvent')}
          onChange={form.handleChange}
          defaultValue={form.values.description}
        />
      </div>

      <fieldset className="form__row">
        <legend className="form__label">{t('textsCommon.image')} (1050 x 360 pixels)</legend>

        <label className="form__custom-file-input">
          <input
            type="file"
            name="image"
            id="image"
            accept=".jpeg, .png, .jpg"
            onChange={handleChangeImage}
            defaultValue={form.values.image}
          />
          <span className="form__control">{imageLabel ? imageLabel : t('textsCommon.placeholderUploadImage')}</span>
        </label>
      </fieldset>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="url"
        >
          URL
        </label>
        <input
          className="form__control"
          id="url"
          name="url"
          type="url"
          placeholder={t('textsCommon.linkToMeeting')}
          onChange={form.handleChange}
          defaultValue={form.values.url}
        />
      </div>

      <button
        type="submit"
        className={isSubmitting ? 'btn btn--primary disabled' : 'btn btn--primary'}
      >
        {t('button.saveContinue')}
      </button>
    </form>
  );
}

export default EventForm;
