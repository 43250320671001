import React, { useState, useEffect } from 'react';
import { Field, Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import styles from '../Login.module.scss';
import LoginLayout from '../LoginLayout';
import Button from 'app/components/Button/Button';
import { FormControl, FormField, FormLabel, FormLink, FormRow, FormFeedback } from 'app/components/Form/Form';
import Dialog, { DialogDescription, DialogTitle } from 'app/components/Dialog/Dialog';
import { useSession } from 'app/hooks/useSession';
import { i18n } from 'app/components/i18n/i18n';
import api from 'app/services/api';
import toast from 'react-hot-toast';

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório')
});

export default function SignIn() {
  const [showModal, setShowModal] = useState(false);
  const { session, signIn, isSubmittingSignIn } = useSession();

  const sso = process.env.REACT_APP_SSO_CLIENT_URL;
  const canCreateAccount = process.env.REACT_APP_ALLOW_CREATE_USER;

  const urlSearchParams = new URLSearchParams(window.location.search);
  // const ssoEtipi = process.env.REACT_APP_ETIPI_SSO_API_URL;

  useEffect(() => {
    if (session && Object.keys(session).length !== 0) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      window.location.pathname = redirectUrl || '/';
    }
  }, [session]);

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);

    if (sso) {
      window.location.replace(sso);
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const done = urlSearchParams.get('done') ?? false;
    const unregistered = urlSearchParams.get('unregistered') ?? false;
    if (done) localStorage.setItem('done', done);
    if (unregistered) localStorage.setItem('unregistered', unregistered);
  }, []);

  const authenticateUser = async e => {
    e.preventDefault();

    console.log('values', values);

    if (values.email && values.password) {
      signIn(values);
    } else {
      toast.error(t('toast.errorSetEmailPass'));
    }
  };

  const handleChange = e => {
    const auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues(auxValues);
  };

  const appName = process.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/horizontal-logo.svg`);

  let bannerImage;

  const sendEmailConfirmation = async values => {
    const lastEmailSent = localStorage.getItem('lastEmailSent');
    if (lastEmailSent) {
      const now = new Date();
      const last = new Date(lastEmailSent);
      const diff = now - last;
      const diffSeconds = Math.floor(diff / 3000);
      if (diffSeconds < 30) {
        toast.error('Aguarde 5 minutos para reenviar o email de confirmação');
        return;
      }

      localStorage.setItem('lastEmailSent', new Date());
    } else {
      localStorage.setItem('lastEmailSent', new Date());
    }

    const toastLoading = toast.loading('Reenviando email de confirmação');

    if (!values.email) {
      return toast.error('Informe o email');
    }

    try {
      const response = await api.post('send_confirmation_email', { email: values.email });
      if (response.status === 200) toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      toast.dismiss(toastLoading);
    }
  };

  return (
    <LoginLayout>
      <Formik
        initialValues={{ email: '', password: '', showPassword: false }}
        validationSchema={SignInSchema}
        onSubmit={async values => {
          if (values.email && values.password) {
            const error = await signIn(values);
            if (error.response) if (error.response.status === 401 && error.response.data.error?.unconfirmed) setShowModal(true);
          } else {
            toast.error(t('toast.errorSetEmailPass'));
          }
        }}
      >
        {props => (
          <>
            <Form>
              <div className={styles.body}>
                <h1 className={styles.title}>Acesse sua conta</h1>
                <FormRow>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <FormControl
                    id="email"
                    name="email"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    invalid={props.errors.email && props.touched.email}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="email">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>

                <FormRow>
                  <FormLabel htmlFor="password">Senha</FormLabel>
                  <FormControl
                    id="password"
                    name="password"
                    type={props.values.showPassword ? 'text' : 'password'}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                    invalid={props.errors.password && props.touched.password}
                    placeholder="Insira sua senha"
                  />

                  <FormField>
                    <Field
                      type="checkbox"
                      name="showPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="password">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>

                <FormRow>
                  <FormLink to="/esqueci-a-senha">Esqueceu sua senha?</FormLink>
                </FormRow>

                <Button
                  modifiers={['outline', 'full']}
                  type="submit"
                  disabled={isSubmittingSignIn}
                >
                  Entrar
                </Button>
              </div>

              {canCreateAccount === 'true' && (
                <div className={styles.footer}>
                  <p className={styles.title}>Ou crie sua conta</p>

                  <Button
                    modifiers={['review', 'full']}
                    anchor
                    to="/criar-uma-conta/aluno/consulta-de-dados"
                  >
                    Criar uma conta nova
                  </Button>
                </div>
              )}
            </Form>

            <Dialog
              show={showModal}
              onClose={() => setShowModal(false)}
            >
              <DialogTitle>Antes de entrar na plataforma...</DialogTitle>
              <DialogDescription>você precisa ativar sua conta. Clique no botão abaixo para reenviarmos o link para o seu email. Abra sua caixa de entrada e ative sua conta em segundos.</DialogDescription>

              <Button
                modifiers={['primary']}
                onClick={() => sendEmailConfirmation(props.values)}
              >
                Reenviar email
              </Button>
            </Dialog>
          </>
        )}
      </Formik>
    </LoginLayout>
  );
}
