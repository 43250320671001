import React, { useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import styles from '../Login.module.scss';
import LoginLayout from '../LoginLayout';
import Button from 'app/components/Button/Button';
import Form, { FormControl, FormFeedback, FormLabel, FormRow } from 'app/components/Form/Form';
import Dialog, { DialogDescription, DialogTitle } from 'app/components/Dialog/Dialog';
import api from 'app/services/api';

const LostPasswordSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório')
});

export default function LostPassword() {
  const [showModal, setShowModal] = useState(false);

  return (
    <LoginLayout back="/login">
      <div className={styles.body}>
        <div className={styles.textGroup}>
          <h1 className={styles.title}>Insira seu email</h1>
          <p className={styles.description}>Insira o email que você cadastrou na plataforma e nós enviaremos um link para uma nova senha </p>
        </div>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={LostPasswordSchema}
          onSubmit={values => {
            api
              .post('/auth/password', { email: values.email, app: true })
              .then(response => {
                console.log({ response });
                toast.success('Link enviado para o email cadastrado');
                setShowModal(true);
              })
              .catch(error => {
                toast.error('Erro ao enviar email');
                console.log(error);
              });
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <FormRow>
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormControl
                  id="email"
                  name="email"
                  type="email"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  invalid={props.errors.email && props.touched.email}
                  placeholder="Insira seu e-mail"
                />
                <ErrorMessage name="email">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
              </FormRow>

              <Button
                modifiers={['primary', 'full']}
                type="submit"
              >
                Enviar
              </Button>
            </Form>
          )}
        </Formik>

        <Dialog
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <DialogTitle>Verifique seu email de cadastro</DialogTitle>
          <DialogDescription>Enviamos um email com instruções sobre a nova senha. Verifique a caixa de entrada, pasta de spam e lixo eletrônico do seu email e siga os passos.</DialogDescription>
        </Dialog>
      </div>
    </LoginLayout>
  );
}
