import React from 'react';
import { Link } from '@reach/router';
import styles from './Button.module.scss';

function ButtonWrapper(props) {
  const { children, ...restProps } = props;

  return (
    <div
      className={styles.btnWrapper}
      {...restProps}
    >
      {children}
    </div>
  );
}

function Button(props) {
  const { type = 'button', children, modifiers, anchor, to = '/', ...restProps } = props;

  let buttonClass = styles.btn;
  if (modifiers?.includes('primary')) buttonClass = `${buttonClass} ${styles.primary}`;
  if (modifiers?.includes('outline')) buttonClass = `${buttonClass} ${styles.outline}`;
  if (modifiers?.includes('full')) buttonClass = `${buttonClass} ${styles.full}`;
  if (modifiers?.includes('review')) buttonClass = `${buttonClass} ${styles.review}`;

  if (!anchor)
    return (
      <button
        type={type}
        className={buttonClass}
        {...restProps}
      >
        {children}
      </button>
    );

  return (
    <Link
      to={to}
      type={type}
      className={buttonClass}
      {...restProps}
    >
      {children}
    </Link>
  );
}

export { Button as default, ButtonWrapper };
