import axios from 'axios';
// export const getToken = () => localStorage.getItem(TOKEN_KEY);
const baseURL = () => {
  const env = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEV_URL;

  return env;
};

const api = axios.create({
  baseURL: baseURL()
});

export const getToken = () => {
  const session = JSON.parse(localStorage.getItem('session'));
  if (session && session.user) {
    return session.user['auth-token'] || session.user.authToken;
  }
};

export const createStudent = async studentData => {
  try {
    return await api.post(`/users`, { data: studentData });
  } catch (error) {
    return error;
  }
};

export const formatStudentData = data => {
  const attributes = {
    'selected-profile-name': 'Aluno',
    classroom_ids: [data.class],
    name: data.name,
    email: data.email,
    password: data.password,
    phone: data.cellPhone,
    cpfCnpj: data.cpf,
    'municipality-id': data.municipality,
    'is-enrolled-in-seduc': true,
    ra: data.ra
  };

  return { attributes };
};

export const login = async data => {
  try {
    const { email, password } = data;
    return await api.post(`/authenticate`, { email, password });
  } catch (error) {
    return error;
  }
};

export const cryptAuthData = data => {
  const stringfyData = JSON.stringify(data);
  const encryptedData = btoa(stringfyData);

  return encryptedData;
};
