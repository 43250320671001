export default function SimulationIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.46184 8.30777L9.38492 9.23084L11.2311 7.38469M14.0003 8.30777H16.6313M8.46184 13.8462L9.38492 14.7693L11.2311 12.9232M13.8077 13.8462H16.5M6.61569 3.69238H17.6926C18.7122 3.69238 19.5388 4.51893 19.5388 5.53854V17.3847C19.5388 18.4893 18.6433 19.3847 17.5388 19.3847H6.61569C5.59608 19.3847 4.76953 18.5581 4.76953 17.5385V5.53854C4.76953 4.51893 5.59608 3.69238 6.61569 3.69238Z"
        stroke="#FD6B62"
        strokeLinecap="round"
      />
    </svg>
  );
}
