export function getSupportUrl(appName) {
  switch (appName) {
    case 'encceja':
      return 'https://suportecanaleducacao.zendesk.com/hc/pt-br/requests/new';
    case 'pit':
      return 'https://pitcanaleducacao.zendesk.com/hc/pt-br/requests/new';
    case 'maiseducacao':
      return 'https://suportemaisformacao.zendesk.com/hc/pt-br/requests/new';
    case 'canaleducacao':
      return 'https://suportecanaleducacao.zendesk.com/hc/pt-br/requests/new';
    case 'egepi':
      return 'https://escoladegoverno.zendesk.com/hc/pt-br/requests/new';
    default:
      return 'https://suporte-plataforma.zendesk.com/hc/pt-br/requests/new';
  }
}
