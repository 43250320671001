import React, { useMemo } from 'react';
import Swal from 'sweetalert2';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from '@reach/router';
import Dropdown from '../Dropdown';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import IconVerified from '../Icons/IconVerified';
import { useSession } from 'app/hooks/useSession';
import IconExercises from '../Icons/IconExercises';

export const QuestionBookCard = props => {
  const { t } = useTranslation();
  const { questionBook, deleteQuestionBook } = props;
  const client = useClient();
  const currentUser = useSession().session.user;
  const authorityLevel = getAuthorityLevel();

  const form = useFormik({
    initialValues: {
      id: questionBook.id,
      published: questionBook.published
    }
  });

  const handleDelete = id => {
    Swal.fire({
      title: t('projects.warningDeleteQuestionBook'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteQuestionBook(id);
      }
    });
  };

  const updateQBPublicity = async values => {
    const { error } = await client.mutate(['question_books', questionBook.id], {
      published: !values.published
    });
    if (error) {
      toast.error(t('toast.errorPublishBook'));
    } else {
      if (!values.published === true) {
        toast.success(t('toast.successPublish'));
      } else {
        toast.success(t('toast.successUnpublish'));
      }
    }
  };

  const shouldRenderOption = useMemo(() => {
    if (questionBook?.['authored-by-user'] === true) {
      if (questionBook?.creator?.id === currentUser.id || currentUser?.profileNames?.includes('Administrador')) {
        return true;
      } else return false;
    }

    if (questionBook?.['authored-by-user'] === false) {
      if (getPermission('Editar módulos, disciplinas e aulas', 'Cursos') || getPermission('Excluir módulos, disciplinas e aulas', 'Cursos')) {
        return true;
      } else return false;
    }
  }, [questionBook]);

  return (
    <div className="card">
      {authorityLevel === 'admin' && (
        <div className="form__check form__switch">
          <input
            className="form__check-input"
            id={`publish-${form.values.id}`}
            name={`publish-${form.values.id}`}
            type="checkbox"
            checked={form.values.published}
            onClick={() => {
              form.setFieldValue('published', !form.values.published);
              updateQBPublicity(form.values);
            }}
          />
          <label htmlFor={`publish-${form.values.id}`}>{t('exams.publish')}</label>
        </div>
      )}

      <div className="card__container">
        <span className="card__icon-wrapper card__icon-wrapper--sm">
          {questionBook?.['official-content'] && <IconVerified />}
          <IconExercises className="card__icon card__icon--md" />
        </span>

        <div className="card__content">
          <div className="card__header">
            <div className="card__inner-header">
              <h2
                className="card__title"
                title={questionBook?.title}
              >
                {questionBook?.title}
              </h2>
            </div>
          </div>

          <div className="avaliation-card__body">
            <p className="avaliation-card__info">
              {questionBook['question-count']}
              <span className="avaliation-card__name"> questões</span>
            </p>

            <p className="avaliation-card__info">{questionBook['t-difficulty']}</p>
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          <Link
            to={`/apostilas/gerenciar/${questionBook.id}`}
            className="btn btn--outline btn--small"
          >
            {t('button.manage')}
          </Link>
          {shouldRenderOption && (
            <Dropdown className="btn btn--outline btn--small">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Content>
                {getPermission('Editar módulos, disciplinas e aulas', 'Cursos') && <Dropdown.Item to={`/apostilas/criar/nome/${questionBook.id}`}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir módulos, disciplinas e aulas', 'Cursos') && <Dropdown.Item onClick={() => handleDelete(questionBook.id)}>{t('button.delete')}</Dropdown.Item>}
              </Dropdown.Content>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};
