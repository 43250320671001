import React from 'react';
import styles from './Form.module.scss';
import { Link } from '@reach/router';
import Select from 'react-select';
import { Field } from 'formik';
import { FiSearch } from 'react-icons/fi';
import './react-select.scss';

function FormTerms() {
  return (
    <textarea
      className={styles.terms}
      rows={5}
      defaultValue="O https://plataforma.canaleducacao.tv/(“Website”) é pertencente e operado pela HF TECNOLOGIA LTDA, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 40.432.531/0001-78, com sede no SCN Quadra 05, Brasília Shopping and Towers, Bloco A, Sala 118, CEP: 70.715-900. Você pode entrar em contato conosco pelo e-mail suporte@suporte-plataforma.com.br.
2. A HF TECNOLOGIA, por meio do Website e do Aplicativo, provê uma plataforma educacional paraalunos, professores e demais atuantes do ecossistema educacional.
3. Estes Termos de Uso, em conjunto com os demais contratos e a nossa Política de Privacidade, estabelecem os termos e condições aplicáveis à utilização de nosso Website. É muito importante que você leia e compreenda estas regras, bem como outras regras que possam ser aplicáveis, incluindo aquelas advindas da Lei nº 8.078/1990 (“Código de Defesa do Consumidor”).
4. Para desfrutar das funcionalidades e para acessar as áreas do Website e do Aplicativo, será requerido que VOCÊ confirme expressamente sua anuência com as Condições Gerais (Parte I abaixo) e os Termos e Condições Adicionais (Parte II abaixo) antes do seu primeiro acesso."
      readOnly
    />
  );
}

function FormCustomSelect(props) {
  const { name, options, values, placeholder = 'Pesquise', onChange, ...restProps } = props;

  function DropdownIndicator() {
    return (
      <FiSearch
        size={20}
        color="#5D6A86"
      />
    );
  }

  return (
    <Field
      name={name}
      {...restProps}
    >
      {({ field, form }) => (
        <Select
          {...field}
          className="react-select"
          classNamePrefix="react-select"
          value={options.find(option => option.value === values[name])}
          options={options}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          onChange={option => {
            form.setFieldValue(name, option.value);
            if (onChange) onChange(option.value);
          }}
          placeholder={placeholder}
          noOptionsMessage={() => 'Sem opções'}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator
          }}
        />
      )}
    </Field>
  );
}

function FormFieldGradeWrapper(props) {
  const { children, ...restProps } = props;

  return (
    <div
      className={styles.fieldGradeWrapper}
      {...restProps}
    >
      {children}
    </div>
  );
}

function FormFieldGrade(props) {
  const { children, modifiers, ...restProps } = props;

  let fieldGradeClass = styles.fieldGrade;
  if (modifiers?.includes('firstGrade')) fieldGradeClass = `${fieldGradeClass} ${styles.firstGrade}`;
  if (modifiers?.includes('secondGrade')) fieldGradeClass = `${fieldGradeClass} ${styles.secondGrade}`;
  if (modifiers?.includes('thirdGrade')) fieldGradeClass = `${fieldGradeClass} ${styles.thirdGrade}`;
  if (modifiers?.includes('eja')) fieldGradeClass = `${fieldGradeClass} ${styles.eja}`;

  return (
    <label
      className={fieldGradeClass}
      {...restProps}
    >
      {children}
    </label>
  );
}

function FormStep(props) {
  const { children, show } = props;

  if (show) return <>{children}</>;
  return null;
}

function FormFeedback(props) {
  const { children, valid, invalid } = props;

  let feedbackClass = styles.feedback;
  if (valid) feedbackClass = `${feedbackClass} ${styles.valid}`;
  if (invalid) feedbackClass = `${feedbackClass} ${styles.invalid}`;

  return <div className={feedbackClass}>{children}</div>;
}

function FormLink(props) {
  const { to = '/', children } = props;

  return (
    <Link
      className={styles.link}
      to={to}
    >
      {children}
    </Link>
  );
}

function FormField(props) {
  const { children, round } = props;

  let fieldClass = styles.field;
  if (round) fieldClass = `${fieldClass} ${styles.round}`;

  return <label className={fieldClass}>{children}</label>;
}

const FormControl = React.forwardRef((props, ref) => {
  const { type = 'text', name, valid, invalid, ...restProps } = props;

  let controlClass = styles.control;
  if (valid) controlClass = `${controlClass} ${styles.valid}`;
  if (invalid) controlClass = `${controlClass} ${styles.invalid}`;

  return (
    <Field
      className={controlClass}
      type={type}
      name={name}
      innerRef={ref}
      {...restProps}
    />
  );
});

function FormLabel(props) {
  const { children, ...restProps } = props;

  return (
    <label
      className={styles.label}
      {...restProps}
    >
      {children}
    </label>
  );
}

function FormRow(props) {
  const { children, disabled, ...restProps } = props;

  let rowClass = styles.row;
  if (disabled) rowClass = `${rowClass} ${styles.disabled}`;

  return (
    <div
      className={rowClass}
      {...restProps}
    >
      {children}
    </div>
  );
}

function Form(props) {
  const { children, ...restProps } = props;

  return (
    <form
      className={styles.form}
      {...restProps}
    >
      {children}
    </form>
  );
}

export { Form as default, FormRow, FormLabel, FormControl, FormField, FormLink, FormFeedback, FormStep, FormFieldGrade, FormFieldGradeWrapper, FormCustomSelect, FormTerms };
