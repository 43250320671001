import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Avatar from '../Avatar';
import tiaAvatar from './img/tia-avatar.png';
import TIAResults from './TIAResults';

function TIA({ message, login }) {
  let tiaClass = 'tia';
  if (login) tiaClass = `${tiaClass} tia--login`;

  return (
    <div
      className={tiaClass}
      style={{ zIndex: 9999 }}
    >
      <Avatar
        className="avatar--md"
        src={tiaAvatar}
        alt="T.I.A"
        data-tooltip-id="tia"
        data-tooltip-place="top-start"
        data-tooltip-variant="light"
        data-clickable
      />

      <ReactTooltip
        clickable
        id="tia"
        style={{ boxShadow: '0px 4px 10px rgba(68, 43, 140, 0.2)', zIndex: 1000 }}
      >
        <div
          className="tia__tooltip"
          style={{ zIndex: 1001 }}
        >
          <h3 className="tia__title">T.I.A</h3>
          <p className="tia__text">{message}</p>
        </div>
      </ReactTooltip>
    </div>
  );
}

export { TIA as default, TIAResults };
