import React from 'react';
import styles from './Icon.module.scss';

function IconWrapper(props) {
  const { children, ...restProps } = props;

  return (
    <div className={styles.wrapper} aria-hidden="true" {...restProps}>
      {children}
    </div>
  );
}

function Icon(props) {
  const { children } = props;

  return (
    <div className={styles.icon} aria-hidden="true">
      {children}
    </div>
  );
}

export { Icon as default, IconWrapper };
