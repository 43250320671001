import React, { useState, useContext } from 'react';
import Dropdown from 'app/components/Dropdown';
import { FiChevronDown, FiClipboard } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { ProjectsContext } from '../provider';
import Modal from 'app/components/Modal';
import { Link } from '@reach/router';
import { ProjectForm } from './ProjectForm';
import getPermission from 'app/utils/getPermission';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function GroupProjectCard(props) {
  const { t } = useTranslation();
  const { data, id, deleteGroupProject, deleteIndividualProject, deletePersonalizedProject, individual, personalized, group } = props;
  const [editShow, setEditShow] = useState(false);
  const [hasOngoing, setHasOngoing] = useState(true);

  const { selectedProject } = useContext(ProjectsContext);

  const thereArePendingVersions = projects => {
    return projects.some(pv => pv.approved === null || pv.approved === false);
  };

  useEffect(() => {
    if (data) {
      setHasOngoing(data.projects.some(p => p.approved === null));
    }
  }, []);

  return (
    <div
      className="card card--group"
      key={data.id}
    >
      <div className="card__container">
        <span className="card__icon-wrapper card__icon-wrapper--sm">
          <FiClipboard className="card__icon card__icon--md" />
        </span>

        <div className="card__content">
          <div className="card__header">
            <div className="card__inner-header">
              <h3 className="card__title">{data?.projects?.[0]?.name}</h3>
            </div>

            {hasOngoing ? <span className="badge badge--primary badge--tiny">{t('exams.inProgress')}</span> : <span className="badge badge--success badge--tiny">{t('exams.statusInApproved')}</span>}

            {!thereArePendingVersions(data.projects) ? (
              <span
                style={{ marginLeft: 5 }}
                className="badge badge--tiny badge--warning"
              >
                {t('projects.pendingEvaluation')}
              </span>
            ) : null}
          </div>

          <div className="card__body">
            <p className="card__count">
              {data?.projects?.length} {t('textsCommon.projects')}
            </p>
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          <Link
            to={`/projetos/grupos/${id}`}
            type="button"
            className="btn btn--outline btn--small"
          >
            {t('projects.accessProject')}
          </Link>

          {getPermission('Editar projetos', 'Projetos') || getPermission('Excluir projetos', 'Projetos') ? (
            <Dropdown className="c-dropdown btn btn--outline btn--small">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar projetos', 'Projetos') && <Dropdown.Item to={`/projetos/criar/passo/curso-turma/${data.id}`}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir projetos', 'Projetos') && (
                  <Dropdown.Item
                    onClick={() => {
                      Swal.fire({
                        title: t('projects.warningDeleteProject'),
                        confirmButtonText: t('button.yes'),
                        showDenyButton: true,
                        denyButtonText: t('button.no'),
                        showCloseButton: true
                      }).then(res => {
                        if (res.isConfirmed) {
                          if (group === true) {
                            deleteGroupProject(data.id);
                          } else if (individual === true) {
                            deleteIndividualProject(data.id);
                          } else if (personalized === true) {
                            deletePersonalizedProject(data.id);
                          }
                        }
                      });
                    }}
                  >
                    {t('textsCommon.delete')}
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </div>

      <Modal
        show={editShow}
        onClose={() => setEditShow(false)}
      >
        <ProjectForm
          labelForm={'Editar projeto'}
          labelFormButton={'Editar e continuar'}
          currentProject={selectedProject}
        />
      </Modal>
    </div>
  );
}
