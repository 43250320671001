import { Link, navigate } from '@reach/router';
import React from 'react';

const FastAccessWidget = ({ title, icon, link }) => {
  return (
    <div
      className="kpi-widget kpi-widget--fast-access"
      onClick={() => navigate(link)}
    >
      <h3 className="kpi-widget__title">{title}</h3>
      <span
        style={{ height: 'fit-content', backgroundColor: '#F1F5FA', width: 'fit-content' }}
        className="card__icon-wrapper"
      >
        {icon}
      </span>
    </div>
  );
};

export default FastAccessWidget;
