import React from 'react';
import { Link } from '@reach/router';
import { FiChevronDown, FiUsers } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import moment from 'moment';
import getPermission from 'app/utils/getPermission';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import { PiStudent } from 'react-icons/pi';

export default function ClassroomCard(props) {
  const { classroom, editClassroomForm, deleteClassroom, setIsEditing } = props;

  const date = classroom && classroom['end-date'];

  const handleEdit = () => {
    setIsEditing(true);
    editClassroomForm(classroom);
  };

  return (
    <div className="card">
      <div className="card__container">
        <span className="card__icon-wrapper card__icon-wrapper--sm">
          <PiStudent className="card__icon card__icon--md" />
        </span>

        <div className="card__content">
          <div className="card__header">
            <div className="card__inner-header">
              <h3
                className="card__title"
                title={classroom?.title}
              >
                {classroom?.title}
              </h3>
            </div>

            <div className="expanded-calendar__tag expanded-calendar__tag--course">{classroom?.institution?.name}</div>
            <div className="expanded-calendar__tag expanded-calendar__tag--course">{classroom?.course?.title}</div>
          </div>

          <div className="card__body">
            <div className="card__columns">
              {date && (
                <p className="card__info">
                  <span>{t('textsCommon.availableUntil')}:</span> {moment(date).format('DD/MM/YYYY')}
                </p>
              )}
              <div className="card__count">
                <span className="card__count-label">{t('classes.participants')}</span>
                <FiUsers className="admin-course-card__icon" /> {(Number(classroom?.['manager-count']) || 0) + (Number(classroom?.['student-count']) || 0)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {getPermission('Visualizar lista de turmas', 'Turmas') && (
            <Link
              to={`/turmas/gerenciar/${classroom.id}`}
              type="button"
              className="btn btn--outline btn--small"
            >
              {t('textsCommon.participants')}
            </Link>
          )}

          {getPermission('Editar turmas', 'Turmas') || getPermission('Excluir turmas', 'Turmas') ? (
            <Dropdown className="btn--small btn btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar turmas', 'Turmas') && <Dropdown.Item onClick={handleEdit}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir turmas', 'Turmas') && (
                  <Dropdown.Item
                    onClick={() => {
                      Swal.fire({
                        title: t('projects.warningDeleteClass'),
                        text: t('projects.warningDeleteCannotBeUndoneText'),
                        confirmButtonText: t('button.yes'),
                        showDenyButton: true,
                        denyButtonText: t('button.no'),
                        showCloseButton: true
                      }).then(res => {
                        if (res.isConfirmed) {
                          deleteClassroom(classroom);
                        }
                      });
                    }}
                  >
                    {t('button.delete')}
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </div>
    </div>
  );
}
