import React, { useState } from 'react';
import styles from 'app/screens/Login/Login.module.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams, Link, navigate } from '@reach/router';
import toast from 'react-hot-toast';
import { useMask } from '@react-input/mask';
import Button, { ButtonWrapper } from 'app/components/Button/Button';
import { FormControl, FormFeedback, FormLabel, FormRow, FormStep, FormField, FormTerms } from 'app/components/Form/Form';
import PasswordStrength from 'app/components/PasswordStrength';
import Dialog, { DialogDescription, DialogTitle } from 'app/components/Dialog/Dialog';
import { validateCPF } from 'app/utils/validateCPF';

const cpfSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF Inválido', value => validateCPF(value))
});

const nameSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório')
});

const emailSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'E-mails não coincidem')
    .email('E-mail inválido')
    .required('Campo obrigatório')
});

const passwordSchema = Yup.object().shape({
  password: Yup.string().required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não coincidem')
    .required('Campo obrigatório')
});

const cellPhoneSchema = Yup.object().shape({
  cellPhone: Yup.string()
    .required('Campo obrigatório')
    .matches(/^\([1-9]{2}\) [9]{1}[0-9]{4}-[0-9]{4}$/, 'Telefone inválido')
});

const termsSchema = Yup.object().shape({
  terms: Yup.string().required('É necessário aceitar os termos.')
});

export default function TeacherSignUp() {
  const [formValues, setFormValues] = useState({});
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordIsStrong, setPasswordIsStrong] = useState(false);
  const [showErrorCpf, setShowErrorCpf] = useState(false);
  const [showCpfFound, setShowCpfFound] = useState(false);

  console.log('formValues', JSON.stringify(formValues, null, 2));

  const { formStep } = useParams();
  const passwordRef = React.createRef();
  const confirmPasswordRef = React.createRef();
  const cfpRef = useMask({
    mask: '___.___.___-__',
    replacement: { _: /\d/ }
  });
  const cellPhoneRef = useMask({
    mask: '(__) _____-____',
    replacement: { _: /\d/ }
  });

  function handleFormValues(values) {
    setFormValues({ ...formValues, ...values });
  }

  const handleNext = (values, actions) => {
    console.log(JSON.stringify(values, null, 2));
    handleFormValues(values);
    actions.setSubmitting(false);

    if (!formStep) {
      // Se encontrar o CPF do Professor
      if (true) {
        toast.success('CPF validado. Siga preenchendo os dados');
        navigate('/criar-uma-conta/professor/nome');
      }
      if (false) {
        toast.error('CPF não encontrado');
        setShowErrorCpf(true);
      }
      if (false) {
        toast.success('CPF já está cadastrado');
        setShowCpfFound(true);
      }
    }
    if (formStep === 'nome') {
      navigate('/criar-uma-conta/professor/email');
    }
    if (formStep === 'email') {
      navigate('/criar-uma-conta/professor/senha');
    }
    if (formStep === 'senha') {
      navigate('/criar-uma-conta/professor/telefone');
    }
    if (formStep === 'telefone') {
      navigate('/criar-uma-conta/professor/termos');
    }
    if (formStep === 'termos') {
      navigate('/criar-uma-conta/professor/conta-criada');
    }
  };

  return (
    <>
      <FormStep show={!formStep}>
        <Formik
          initialValues={{ cpf: formValues.cpf ?? '' }}
          validationSchema={cpfSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Vamos consultar seus dados na Rede SEDUC</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="cpf">*Digite seu CPF</FormLabel>
                  <FormControl
                    type="text"
                    name="cpf"
                    id="cpf"
                    value={props.values.cpf}
                    invalid={props.errors.cpf && props.touched.cpf}
                    placeholder="000.000.000-00"
                    ref={cfpRef}
                  />
                  <ErrorMessage name="cpf">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>

                <Dialog
                  show={showErrorCpf}
                  onClose={() => setShowErrorCpf(false)}
                >
                  <DialogTitle>Professor(a), entre em contato com a sua escola</DialogTitle>
                  <DialogDescription>
                    Se você está digitando corretamente o seu CPF e mesmo assim o número não foi encontrado, recomendamos que <strong>entre em contato com sua escola informando a necessidade de validar o seu CPF</strong>
                  </DialogDescription>
                </Dialog>

                <Dialog
                  show={showCpfFound}
                  onClose={() => setShowCpfFound(false)}
                >
                  <DialogTitle>Professor(a), este CPF já foi cadastrado</DialogTitle>
                  <DialogDescription>O número de CPF que você está digitando já está cadastrado. Entre na plataforma usando o email de cadastro que está associado a esse CPF.</DialogDescription>

                  <Button
                    modifiers={['primary']}
                    anchor
                    to="/entrar"
                  >
                    Entrar com e-mail
                  </Button>
                </Dialog>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  onClick={() => navigate('/login')}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  disabled={props.isSubmitting || !props.values.cpf}
                >
                  Consultar
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'nome'}>
        <Formik
          initialValues={{ cpf: formValues.cpf, name: formValues.name ?? '' }}
          validationSchema={nameSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Confira seus e faça as correções necessárias. Quando terminar, clique em “Próximo”.</h1>
                </div>

                <FormRow disabled={true}>
                  <FormLabel htmlFor="cpf">CPF</FormLabel>
                  <FormControl
                    id="cpf"
                    name="cpf"
                    type="text"
                    value={props.values.cpf}
                  />
                </FormRow>
                <FormRow>
                  <FormLabel htmlFor="name">*Nome</FormLabel>
                  <FormControl
                    id="name"
                    name="name"
                    type="text"
                    value={props.values.name}
                    placeholder="Insira seu nome completo"
                  />
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  onClick={() => navigate('/criar-uma-conta/professor')}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.name}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'email'}>
        <Formik
          initialValues={{ email: formValues.email ?? '', confirmEmail: formValues.confirmEmail ?? '' }}
          validationSchema={emailSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Preencha com seu email</h1>
                  <p className={`${styles.description} ${styles.box} `}>Toda a comunicação da plataforma será feita pelo email que você cadastrar. Anote-o para não esquecer.</p>
                </div>

                <FormRow>
                  <FormLabel htmlFor="email">*Email</FormLabel>
                  <FormControl
                    id="email"
                    name="email"
                    type="email"
                    value={props.values.email}
                    invalid={props.errors.email && props.touched.email}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="email">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
                <FormRow>
                  <FormLabel htmlFor="confirmEmail">*Confirme seu email</FormLabel>
                  <FormControl
                    id="confirmEmail"
                    name="confirmEmail"
                    type="email"
                    value={props.values.confirmEmail}
                    invalid={props.errors.confirmEmail && props.touched.confirmEmail}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="confirmEmail">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  onClick={() => navigate('/criar-uma-conta/professor/nome')}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.email || !props.values.confirmEmail}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'senha'}>
        <Formik
          initialValues={{ password: formValues.password ?? '', showPassword: formValues.showPassword ?? false, confirmPassword: formValues.confirmPassword ?? '', showConfirmPassword: formValues.showConfirmPassword ?? false }}
          validationSchema={passwordSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Crie uma senha para cadastro.</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="password">*Senha</FormLabel>
                  <PasswordStrength
                    show={showPasswordStrength}
                    password={props.values.password}
                    validPassword={setPasswordIsStrong}
                  >
                    <FormControl
                      id="password"
                      name="password"
                      type={props.values.showPassword ? 'text' : 'password'}
                      onChange={props.handleChange}
                      onFocus={() => {
                        setShowPasswordStrength(true);
                        passwordRef.current.removeAttribute('readOnly');
                      }}
                      onBlur={() => {
                        setShowPasswordStrength(false);
                        passwordRef.current.setAttribute('readOnly', '');
                      }}
                      value={props.values.password}
                      invalid={props.errors.password && props.touched.password}
                      placeholder="Insira sua senha"
                      ref={passwordRef}
                      autoComplete="off"
                      readOnly
                    />
                  </PasswordStrength>
                  <FormField>
                    <Field
                      type="checkbox"
                      name="showPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="password">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>

                <FormRow>
                  <FormLabel htmlFor="confirmPassword">*Confirme sua senha</FormLabel>
                  <FormControl
                    id="confirmPassword"
                    name="confirmPassword"
                    type={props.values.showConfirmPassword ? 'text' : 'password'}
                    onChange={props.handleChange}
                    onFocus={() => confirmPasswordRef.current.removeAttribute('readOnly')}
                    onBlur={() => confirmPasswordRef.current.setAttribute('readOnly', '')}
                    value={props.values.confirmPassword}
                    invalid={props.errors.confirmPassword && props.touched.confirmPassword}
                    placeholder="Insira sua senha"
                    ref={confirmPasswordRef}
                    autoComplete="off"
                    readOnly
                  />
                  <FormField>
                    <Field
                      type="checkbox"
                      name="showConfirmPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="confirmPassword">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  onClick={() => navigate('/criar-uma-conta/professor/email')}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.password || !props.values.confirmPassword || !passwordIsStrong}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'telefone'}>
        <Formik
          initialValues={{ cellPhone: formValues.cellPhone ?? '' }}
          validationSchema={cellPhoneSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Insira o número do seu telefone celular</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="cellPhone">Celular com DDD</FormLabel>
                  <FormControl
                    id="cellPhone"
                    name="cellPhone"
                    type="tel"
                    value={props.values.cellPhone}
                    invalid={props.errors.cellPhone && props.touched.cellPhone}
                    placeholder="(00) 00000-0000"
                    ref={cellPhoneRef}
                  />
                  <ErrorMessage name="cellPhone">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  onClick={() => navigate('/criar-uma-conta/professor/senha')}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.cellPhone}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'termos'}>
        <Formik
          initialValues={{ terms: true }}
          validationSchema={termsSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Termos de uso e política de privacidade</h1>
                </div>

                <FormRow>
                  <FormTerms />
                </FormRow>

                <FormRow>
                  <FormField round>
                    <Field
                      type="checkbox"
                      name="terms"
                    />
                    <span>
                      Ao clicar em “Criar conta”, você concorda com os <Link to="/">termos de uso</Link> e <Link to="/">política de privacidade</Link>
                    </span>
                  </FormField>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  onClick={() => navigate('/criar-uma-conta/professor/telefone')}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.terms}
                >
                  Criar minha conta
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'conta-criada'}>
        <div className={styles.body}>
          <div className={styles.textGroup}>
            <h1 className={styles.title}>Conta foi criada!</h1>
            <p className={styles.description}>Enviamos um email para você. Acesse-o e ative sua conta.</p>
          </div>
        </div>
      </FormStep>
    </>
  );
}
