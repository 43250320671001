import React from 'react';
import { FiCheckCircle, FiCoffee, FiEdit3, FiMessageSquare, FiMonitor, FiUser, FiVideo } from 'react-icons/fi';
import { Link } from '@reach/router';
import { PiStudent } from 'react-icons/pi';
import IconPerformance from 'app/components/Icons/IconPerformance';
import { BsFileEarmarkCheck } from 'react-icons/bs';

export default function FrequentShortcutWidget({ mostVisited }) {
  const getMostVisitedData = path => {
    switch (path) {
      case '/curso/admin':
        return { title: 'Cursos', icon: <FiMonitor className="frequent-shortcut-widget__icon" /> };
      case '/turmas':
        return { title: 'Turmas', icon: <PiStudent className="frequent-shortcut-widget__icon" /> };
      case '/feed':
        return { title: 'Fórum', icon: <FiCoffee className="frequent-shortcut-widget__icon" /> };
      case '/avaliacoes/:selectedTab':
        return { title: 'Avaliações', icon: <FiCheckCircle className="frequent-shortcut-widget__icon" /> };
      case '/aulas-ao-vivo':
        return { title: 'Aulas ao vivo', icon: <FiVideo className="frequent-shortcut-widget__icon" /> };
      case '/auditorio':
        return { title: 'Monitoria', icon: <FiMessageSquare className="frequent-shortcut-widget__icon" /> };
      case '/participantes':
        return { title: 'Usuários', icon: <FiUser className="frequent-shortcut-widget__icon" /> };
      case '/metricas/:selectedTab':
        return { title: 'Relatórios', icon: <IconPerformance className="frequent-shortcut-widget__icon" /> };
      case '/redacao':
        return { title: 'Redação', icon: <FiEdit3 className="frequent-shortcut-widget__icon" /> };
      case '/atividade-complementar':
        return { title: 'Atividade Complementar', icon: <BsFileEarmarkCheck className="frequent-shortcut-widget__icon" /> };
    }
  };

  const formatedPath = path => {
    if (path.includes('metricas/:selectedTab')) {
      return 'metricas/institutional';
    } else if (path.includes('/:selectedTab')) {
      return path.replace('/:selectedTab', '');
    } else {
      return path;
    }
  };

  return (
    <div className="frequent-shortcut-widget-wrapper">
      <h3 className="frequent-shortcut-widget-wrapper__title">Mais acessados por você</h3>

      <div className="frequent-shortcut-widget">
        {mostVisited.slice(0, 4).map(item => (
          <Link
            className="frequent-shortcut-widget__item"
            to={formatedPath(item.path)}
          >
            {getMostVisitedData(item.path).icon}
            <span className="frequent-shortcut-widget__link-text">{getMostVisitedData(item.path).title}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}
