import React from 'react';
import { navigate } from '@reach/router';
import { FiInfo } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import moreMedal from 'app/images/more-medal.svg';
import { t } from 'i18next';
import IconVerified from 'app/components/Icons/IconVerified';

export default function MonitoringModuleCard(props) {
  const { module, courseId, canAccess } = props;

  const getModuleStatus = module => {
    const now = new Date();
    let badgeClass = '';
    let badgeText = '';
    let disclaimer = false;

    if (module?.learning_system_users.length === 0) {
      badgeClass = 'badge badge--tiny badge--warning';
      badgeText = 'Novo';
    }
    if (module?.progress === 100 && now >= new Date(module?.finished_at)) {
      badgeClass = 'badge badge--tiny badge--success';
      badgeText = 'Concluído';
    }
    if (module?.progress === 100 && now < new Date(module?.finished_at)) {
      badgeClass = 'badge badge--tiny badge--warning';
      badgeText = 'Em andamento';
      disclaimer = true;
    }
    if (module?.progress < 100 && module?.progress > 0) {
      badgeClass = 'badge badge--tiny badge--warning';
      badgeText = 'Em andamento';
    }

    return { badgeClass, badgeText, disclaimer };
  };

  const { badgeClass, badgeText, disclaimer } = getModuleStatus(module);

  return (
    <div
      className="module-card"
      onClick={() => navigate(`/curso/${courseId}/modulo/${module.id}/sobre`)}
    >
      <div className="module-card__headerModuleList">
        <div className="module-card__inner-header">
          <div className="admin-course-card--flex-gap-5">
            {module?.['official_content'] && <IconVerified />}
            <h3
              className="module-card__title"
              title={module?.title}
            >
              {module?.title}
            </h3>
          </div>
        </div>
      </div>

      {disclaimer ? (
        <div className="moduleBadge">
          <FiInfo
            data-tooltip-content={`O certificado do módulo estará disponível a partir de ${new Date(module?.finished_at).toLocaleDateString('pt-br')}`}
            size={17}
          />
          <span style={{ wordWrap: 'break-word', width: 400 }}>
            Parabéns, todas as aulas foram concluídas. <b>Mantenha-se atento, mais conteudos podem chegar em breve.</b>
          </span>
        </div>
      ) : (
        <span
          className="card__badges u-mb-0"
          style={{ display: 'inline-flex' }}
        >
          <span className={badgeClass}>{badgeText}</span>
        </span>
      )}

      <p className="module-card__text">
        {module?.progress === 0 ? <strong>0</strong> : <strong>{module?.learning_system_items_finished_count}</strong>}/{module?.learning_system_items_total_count} {t('lms.lms')}
      </p>

      {module?.progress === 100 && (
        <div className="medal medal--sm">
          <img
            src={moreMedal}
            alt="nova medalha"
            className="medal__img"
          />
          <span className="medal__value">
            <AiOutlineTrophy />
          </span>
        </div>
      )}

      <button
        type="button"
        className="btn btn--wide btn--outline"
        disabled={!canAccess}
      >
        Acessar
      </button>

      {/* <div className="module-card__footer">
        {newModule ? (
          <button
            type="button"
            className="btn btn--full btn--outline"
          >
            Visualizar
          </button>
        ) : (
          <>
            <p className="module-card__text">{t('lms.buttonContinue')}</p>
            <Link
              className="module-card__link"
              to={`/show-course/${374}/classroom`}
            >
              <span className="card__icon-wrapper">
                <FiPlayCircle className="card__icon" />
              </span>
              <span className="module-card__link-text">Aula 2 - Física clássica</span>
            </Link>
          </>
        )}
      </div> */}
    </div>
  );
}
