export const mapToFormFields = response => {
  const aluno = response.data.attributes.ALUNO;
  const turma = response.data.attributes.TURMA;

  return {
    inep: aluno?.inep,
    codigoEscolar: aluno?.codigoEscolarAluno,
    cpf: aluno?.cpf,
    name: aluno?.nomePessoa,
    nomeSocial: aluno?.nomeSocial,
    dataNascimento: aluno?.dataNascimento,
    raca: aluno?.raca,
    sexo: aluno?.sexo,
    rgNumero: aluno?.rgNumero,
    rgOrgao: aluno?.rgOrgao,
    rgEstado: aluno?.rgEstado,
    bairro: aluno?.bairro,
    endereco: aluno?.endereco,
    cep: aluno?.cep,
    numeroEndereco: aluno?.numero,
    complemento: aluno?.complemento,
    referencia: aluno?.referencia,
    state: aluno?.estado,
    municipality: aluno?.municipio,
    zonaResidencial: aluno?.zonaResidencial,
    email: aluno?.Email,
    confirmEmail: aluno?.Email,
    cellPhone:
      aluno?.TelefoneEstudante?.match(/\d+/g)
        .join('')
        .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3') ?? '',
    nomeMae: aluno?.Mae,
    nomePai: aluno?.Pai,
    contatoPais: aluno?.ContatoPais,
    nomeTurma: turma?.nomeTurma,
    idTurma: turma?.idTurma,
    idEtapa: turma?.idEtapa,
    nomeEtapa: turma?.nomeEtapa,
    modalidadeEnsino: turma?.nomeModEnsino,
    nomeCurso: turma?.nomeCurso,
    periodo: turma?.periodo,
    statusEnturmacao: turma?.descricaoStatusEnturmacao,
    idStatusEnturmacao: turma?.idStatusEnturmacao,
    statusMatricula: turma?.statusMatricula,
    idMatricula: turma?.idMatricula,
    unidadeEscolar: turma?.Nome,
    idEntidade: turma?.idEntidade,
    inepEscola: turma?.inepEscola,
    turno: turma?.nomeTurno
  };
};
