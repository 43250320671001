import { useCallback } from 'react';
import { FiFilter, FiMonitor, FiMousePointer, FiUsers } from 'react-icons/fi';

export default function MetricsShortcuts({ title, link, icon }) {
  const renderIcon = useCallback(icon => {
    switch (icon) {
      case 'monitor':
        return <FiMonitor size={20} />;
      case 'funil':
        return <FiFilter size={20} />;
      case 'users':
        return <FiUsers size={20} />;
      case 'pointer':
        return <FiMousePointer size={20} />;
    }
  }, []);

  return (
    <div className="metrics-shortcut">
      <div className="metrics-shortcut__content">
        <h3 className="kpi-widget__title">{title}</h3>
        <a
          className="btn--link"
          href={link}
        >
          Acessar relatório
        </a>
      </div>
      <span className="card__icon-wrapper">{renderIcon(icon)}</span>
    </div>
  );
}
