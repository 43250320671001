import React, { useEffect, useState } from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';
import userAvatar from 'app/images/user-avatar.svg';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import toast from 'react-hot-toast';
import { useQuery } from 'jsonapi-react';
import { useSession } from 'app/hooks/useSession';

export default function RankingByParticipant() {
  const [users, setUsers] = useState([]);
  const { data, error, isLoading } = useQuery('/challenges/ranking?users=true');
  const { session } = useSession();
  useEffect(() => {
    if (data) setUsers(data.data);
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error('Erro ao buscar os participantes');
    }
  }, [error]);

  if (isLoading) return <Loader />;

  return (
    <div className="round-dg-wrapper">
      {users?.length === 0 ? (
        <EmptyState
          type="data"
          text="Nenhum participante encontrado"
        />
      ) : (
        <table
          className="round-dg round-dg--light"
          style={{ minWidth: '720px' }}
        >
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '56px' }}
              >
                Posição
              </th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '28px' }}
              />
              <th className="round-dg__cell-header round-dg__cell-header--sm">Estudante</th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '200px' }}
              >
                Unidade escolar
              </th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '160px' }}
              >
                GRE
              </th>
              <th
                className="round-dg__cell-header u-text-right"
                style={{ width: '128px' }}
              >
                Pontuação total
              </th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {users?.map((item, i) => (
              <tr
                key={item.id}
                className={item?.id === session?.user?.id ? 'round-dg__row round-dg__row--highlight' : 'round-dg__row'}
              >
                <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
                <td className="round-dg__cell round-dg__cell--sm">
                  {i === 0 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                  {i === 1 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                  {i === 2 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div className="round-dg__user">
                    <img
                      className="avatar avatar--xxs"
                      src={item?.image?.url || userAvatar}
                      alt="Imagem do usuário"
                    />

                    <div className="round-dg__user-inner">
                      <h3 className="round-dg__title">{item?.name}</h3>
                    </div>
                  </div>
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div
                    className="u-text-truncate"
                    title={item.institution}
                  >
                    {item.institution}
                  </div>
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div
                    className="u-text-truncate"
                    title={item.regional}
                  >
                    {item.regional}
                  </div>
                </td>
                <td className="round-dg__cell u-text-right">{item.score} pontos</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
